import { BorderlessButton } from "components/form/Button";
import ButtonGroup from "components/form/ButtonGroup";
import Verification from "components/user/Verification";
import { VerificationType } from "hooks/useVerifyTransport";
import styled from "styled-components";

import { useOnboardingContext } from "../components/OnboardingUserFlow";
import { useRegisterFormContext } from "../components/RegisterForm";

interface VerificationStepProps {
  onVerificationComplete: (code: string, isWhatsapp: boolean) => void;
  type: VerificationType;
}

export const VerificationStep = ({
  onVerificationComplete,
  type,
}: VerificationStepProps) => {
  const { values } = useRegisterFormContext();
  const { setMainStep } = useOnboardingContext() || {};

  const previousStep = type === "EMAIL" ? "InputEmailStep" : "PhoneStep";

  return (
    <VerificationStepContainer>
      <Verification
        {...(type !== "EMAIL" && { phone: values["phone"] })}
        {...(type === "EMAIL" && { email: values["email"] })}
        onComplete={onVerificationComplete}
      />
      <ButtonGroup>
        <BorderlessButton onClick={() => setMainStep?.(previousStep)}>
          Back
        </BorderlessButton>
      </ButtonGroup>
    </VerificationStepContainer>
  );
};

const VerificationStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  text-align: center;
`;
