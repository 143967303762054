/* eslint-disable sonarjs/no-duplicate-string */
import Fade from "components/animations/Fade";
import Background from "components/base/layout/Background";
import styled from "styled-components";

import OnboardingUserFlow from "./components/OnboardingUserFlow";

export const Register = () => {
  return (
    <StyledBackground color="#f9f7f4">
      <Fade delay={750}>
        <OnboardingUserFlow />
      </Fade>
    </StyledBackground>
  );
};

const StyledBackground = styled(Background)`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1rem;
`;
