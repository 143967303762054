import Button from "components/form/Button";
import ButtonGroup from "components/form/ButtonGroup";
import Field from "components/form/Field";
import Select from "components/form/Select/Select";
import { useGetOnboardingGroupInfo } from "hooks/useGetOnboardingGroupInfo";
import useIsAuth from "hooks/user/useIsAuth";
import useKeyAction from "hooks/utils/useKeyAction";
import { ComponentProps, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { useOnboardingContext } from "../components/OnboardingUserFlow";
import { useRegisterFormContext } from "../components/RegisterForm";

export const PronoumsStep = () => {
  const isAuth = useIsAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { errors, handleSubmit } = useRegisterFormContext();
  const { setMainStep } = useOnboardingContext() || {};
  const { poolSlug } = useParams<{ poolSlug: string }>();
  const { poolInfo } = useGetOnboardingGroupInfo(poolSlug);

  const isDisabled = !!errors.pronouns;

  const handleClick = useKeyAction({
    key: "Enter",
    action: async e => {
      if (isDisabled) return;
      handleSubmit();
      setIsLoading(true);
      e.preventDefault();
    },
  });

  useEffect(() => {
    const nextStep = poolInfo?.data.isPaid ? "PaymentStep" : "JoinLabStep";
    if (isAuth) setMainStep?.(nextStep);
  }, [isAuth, poolInfo?.data.isPaid, setMainStep]);

  return (
    <NameAndTimezoneInputStepContainer>
      <div>
        <h2>What pronouns do you use?</h2>
        <Field name="pronouns" as={PronoumsSelect} />
      </div>
      <ButtonGroup>
        <Button
          type={"button"}
          disabled={isDisabled}
          onClick={handleClick}
          loading={isLoading}
        >
          Continue
        </Button>
      </ButtonGroup>
    </NameAndTimezoneInputStepContainer>
  );
};

export function PronoumsSelect(props: ComponentProps<typeof Select>) {
  const { name } = props;
  return (
    <Select label="Pronouns" placeholder="Enter pronouns" id={name} {...props}>
      <option value="HE/HIM">He/Him</option>
      <option value="SHE/HER">She/Her</option>
      <option value="THEY/THEM">They/Them</option>
    </Select>
  );
}

const NameAndTimezoneInputStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  h2 {
    font-size: 1.375rem;
    font-weight: 500;
    margin-bottom: 2rem;
    text-align: center;
  }
`;
