import { ISendAuthLinkReq } from "@dailyhuman/web-core/dist/api/auth";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { SnackBarContext } from "components/base/Alert/Alert";
import { useContext } from "react";
import { apiRoute } from "utils/api";

type AuthResType = {
  sentTo: string;
};

type AuthMagicLinkError = {
  error: {
    message: string;
    fields?: { field: string; message: string }[];
  };
};

function useSendMagicLink(cb?: () => void) {
  const context = useContext(SnackBarContext);

  const { mutate: sendMagicLink, isPending: isSendingLink } = useMutation({
    mutationFn: (data: ISendAuthLinkReq) =>
      axios.post<AuthResType, ISendAuthLinkReq>(
        `${apiRoute}/auth/send-auth-link`,
        data,
      ),
    onSuccess: () => {
      cb?.();
    },
    onError: (error: AxiosError<AuthMagicLinkError>) => {
      context?.setSnackBar({
        status: "failure",
        text: error.response?.data?.error.message || "Error sending magic link",
      });
    },
  });

  return { sendMagicLink, isSendingLink };
}

export { useSendMagicLink };
