import dayjs from "dayjs";
import { ComponentProps, useRef } from "react";
import styled from "styled-components";

import { SelectView } from "./Select/Select";

export interface DateSelectProps<T> extends ComponentProps<typeof InvisibleInput> {
  name?: keyof T;
  error?: string;
  focused?: boolean;
  loading?: boolean;
  icon?: JSX.Element;
}

export default function DateSelect<T>(props: DateSelectProps<T>) {
  const ref = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    ref.current?.focus();
    ref.current?.showPicker();
  };

  const content = props.value ? (
    dayjs(props.value).format("DD MMM YYYY")
  ) : (
    <Placeholder>{props.placeholder}</Placeholder>
  );

  return (
    <Container>
      <SelectView icon={<Icon />} onClick={handleClick}>
        {content}
      </SelectView>
      <InvisibleInput {...props} ref={ref} type="date" />
    </Container>
  );
}

const Container = styled.div`
  cursor: pointer;
  position: relative;
`;

const Placeholder = styled.span`
  color: gray;
`;

const InvisibleInput = styled.input`
  background: transparent;
  bottom: 0;
  height: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
`;

const Icon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <rect x="1" y="3" width="14" height="12" rx="2" stroke="#F9F7F4" strokeWidth="2" />
    <path
      d="M5 1C5 0.447715 4.55228 0 4 0C3.44772 0 3 0.447715 3 1H5ZM3 1V3H5V1H3Z"
      fill="#F9F7F4"
    />
    <path
      d="M13 1C13 0.447715 12.5523 0 12 0C11.4477 0 11 0.447715 11 1H13ZM11 1V3H13V1H11Z"
      fill="#F9F7F4"
    />
    <path
      d="M15 7H1V5C1 3.89543 1.89543 3 3 3H13C14.1046 3 15 3.89543 15 5V7Z"
      fill="#F9F7F4"
    />
  </svg>
);
