import { useNavigateWithFade } from "components/PageRouter/PageRouter";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

type LinkMenuPropsType = {
  title: string;
  path: string;
  icon?: any;
  color?: string;
};

export default function MenuItem({ title, path, icon, color }: LinkMenuPropsType) {
  const Icon = icon;
  const navigate = useNavigateWithFade();
  return (
    <StyledNavLink
      to={path}
      color={color}
      onClick={e => {
        e.preventDefault();
        navigate(path);
      }}
    >
      {icon && <Icon />}
      <Title>{title}</Title>
    </StyledNavLink>
  );
}

const StyledNavLink = styled(NavLink)<{ color?: string }>`
  align-items: center;
  color: #241f18;
  display: flex;
  &:hover {
    color: ${({ color }) => color};
  }
`;

const Title = styled.span`
  font-size: 1.375rem;
  font-style: normal;
  font-weight: bold;
  line-height: 2rem;
  margin-left: 20px;
  margin-top: 5px;
  transition: color 0.2s ease-out;
`;
