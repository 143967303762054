import Heading1 from "components/base/text/Heading1";
import Heading3 from "components/base/text/Heading3";
import Small from "components/base/text/Small";
import { Section } from "components/Journey/JourneySection";
import dayjs from "dayjs";
import styled from "styled-components";

export default function AnalyticsUsersListItem({ cycle }: { cycle: any }) {
  return (
    <ItemContainer>
      <ItemCard>
        <ItemCardImage src={cycle.card?.image} alt={cycle.prompt.name} />
        <ItemCardContent>
          <div>
            <Heading3>{cycle.prompt.name}</Heading3>
            <Small>{dayjs(cycle.cycle.startAt).format("MMM DD, YYYY")}</Small>
          </div>
          <div>
            <Heading1>{cycle.card?.name}</Heading1>
            MINDSET CARD
          </div>
        </ItemCardContent>
      </ItemCard>
      <ItemSection>
        <Heading3>{cycle.user.name}</Heading3>
      </ItemSection>
    </ItemContainer>
  );
}

const ItemCard = styled.div`
  margin: 0 auto 1.5rem;
  position: relative;
`;

const ItemCardImage = styled.img`
  border-radius: 1rem;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.2);
  display: block;
  height: 513px;
  margin: 0 auto;
  max-width: 100%;
  object-fit: cover;
`;

const ItemCardContent = styled.div`
  bottom: 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  line-height: 1;
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 0;
`;

const ItemContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: calc(100% - 1rem);
`;

const ItemSection = styled(Section)`
  color: #664df3;
  margin-top: -12rem;
  padding-top: 12rem;
`;
