import Button, { BorderlessButton } from "components/form/Button";
import ButtonGroup from "components/form/ButtonGroup";
import Field from "components/form/Field";
import PhoneInput from "components/form/PhoneInput";
import useUserExists from "hooks/useCheckUserExist";
import useKeyAction from "hooks/utils/useKeyAction";
import styled from "styled-components";

import { useOnboardingContext } from "../components/OnboardingUserFlow";
import { useRegisterFormContext } from "../components/RegisterForm";

export default function PhoneStep() {
  const { errors, values, setFieldValue } = useRegisterFormContext();
  const { setMainStep } = useOnboardingContext() || {};
  const { userExistsRefetch, isUserExistsLoading } = useUserExists({
    phone: values.phone,
  });

  const isDisabled = !values.phone || isUserExistsLoading || !!errors.phone;

  const handleClick = async (e: KeyboardEvent) => {
    e.preventDefault();
    if (isDisabled) return;
    const { data } = await userExistsRefetch();
    const exists = data?.data.exists;
    setMainStep?.(exists ? "UserExistsStep" : "PhoneVerificationStep");
  };

  const handleEmailClick = () => {
    setMainStep?.("InputEmailStep");
    setFieldValue("phone", "");
  };

  useKeyAction({ key: "Enter", action: handleClick });

  return (
    <Wrapper>
      <FieldWrapper>
        <h2>Enter phone number</h2>
        <Field name="phone" as={PhoneInput} enterKeyHint="next" />
      </FieldWrapper>
      <ButtonGroup>
        <BorderlessButton onClick={handleEmailClick}>
          Use email instead
        </BorderlessButton>
        <Button
          disabled={isDisabled}
          loading={isUserExistsLoading}
          onClick={handleClick}
        >
          Continue
        </Button>
        <BorderlessButton onClick={() => setMainStep?.("InitialStep")}>
          Back
        </BorderlessButton>
      </ButtonGroup>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  h2 {
    font-size: 1.375rem;
    font-weight: 500;
    margin-bottom: 2rem;
    text-align: center;
  }
`;

const FieldWrapper = styled.div`
  flex: 1;
`;
