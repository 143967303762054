import Fade from "components/animations/Fade";
import Switch from "components/animations/Switch";
import Loading from "components/base/Loading";
import useTranscribe from "hooks/labs/useTranscribe";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import RecordIndicator from "./RecordIndicator";

type TranscribeWidgetProps = {
  onTranscribe: (transcription: string) => void;
  message: string;
};

export default function TranscribeWidget({
  onTranscribe,
  ...props
}: TranscribeWidgetProps) {
  const [shouldReplace, setShouldReplace] = useState(false);
  const {
    startRecording,
    stopRecording,
    cancelRecording,
    isRecording,
    isFetchingTranscription,
    transcribeError,
  } = useTranscribe();

  const handleRecordStopClick = async (e: any) => {
    const transcription = await stopRecording();
    if (transcription) {
      const newMessage = shouldReplace
        ? transcription
        : props.message + " " + transcription;
      onTranscribe(String(newMessage));
    }
  };

  const handleShouldReplaceClick = (shouldReplace: boolean) => {
    setShouldReplace(shouldReplace);
    startRecording();
  };

  const handleCloseClick = useCallback(() => {
    cancelRecording();
    onTranscribe(props.message);
  }, [cancelRecording, onTranscribe, props.message]);

  useEffect(() => {
    if (!props.message) startRecording();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (transcribeError) {
      alert(transcribeError);
      handleCloseClick();
    }
  }, [handleCloseClick, transcribeError]);

  const renderContent = () => {
    if (isRecording)
      return (
        <LoadingContainer>
          <CloseContainer onClick={handleCloseClick}>
            <CloseIcon />
          </CloseContainer>
          <RecordIndicator onClick={handleRecordStopClick} />
          <div>Tap the mic to finish recording</div>
        </LoadingContainer>
      );

    if (isFetchingTranscription)
      return (
        <LoadingContainer>
          <Loading style={{ width: "150px" }} />
          <h3>Converting to text</h3>
        </LoadingContainer>
      );
    return (
      <LoadingContainer>
        <CloseContainer onClick={handleCloseClick}>
          <CloseIcon />
        </CloseContainer>
        <h3>What do you want to do?</h3>
        <ReplaceContainer>
          <button onClick={() => handleShouldReplaceClick(false)}>
            <AppendIcon />
            Add more Content
          </button>
          <button onClick={() => handleShouldReplaceClick(true)}>
            <ReplaceIcon />
            Replace Content
          </button>
        </ReplaceContainer>
      </LoadingContainer>
    );
  };

  return (
    <Fade delay={300}>
      <Switch renderKey={`${isFetchingTranscription}-${isRecording}`}>
        {renderContent()}
      </Switch>
    </Fade>
  );
}

const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 0 2rem;
  position: relative;
  text-align: center;
  & > h3 {
    font-weight: bold;
    margin: 0 0 1rem;
  }
`;

const ReplaceContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  & > button {
    background: #f9f7f4;
    border: none;
    border-radius: 1rem;
    color: #664df3;
    cursor: pointer;
    displat: flex;
    font-size: 1rem;
    padding: 1rem;
    & > svg {
      display: block;
      margin: 0 auto 0.5rem;
    }
  }
`;

const CloseContainer = styled.div`
  cursor: pointer;
  position: absolute;
  right: 2rem;
  top: 1rem;
`;

const ReplaceIcon = () => (
  <svg width="37" height="36" fill="none">
    <rect x="0.75" width="36" height="36" rx="18" fill="#664DF3" />
    <path
      d="M12 11.9497L16.9498 7"
      stroke="#F9F7F4"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M12 11.9497L16.9498 16.8995"
      stroke="#F9F7F4"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M13 12H20C23.866 12 27 15.134 27 19V19C27 22.866 23.866 26 20 26H13"
      stroke="#F9F7F4"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

const AppendIcon = () => (
  <svg width="37" height="36" viewBox="0 0 37 36">
    <rect x="0.25" width="36" height="36" rx="18" fill="#664DF3" />
    <path d="M11.5 18H25.5" stroke="#F9F7F4" strokeWidth="2" strokeLinecap="round" />
    <path d="M18.5 11V25" stroke="#F9F7F4" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

const CloseIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20">
    <path d="M1 19L19 1" stroke="#AB997E" strokeWidth="2" strokeLinecap="round" />
    <path d="M1 1L19 19" stroke="#AB997E" strokeWidth="2" strokeLinecap="round" />
  </svg>
);
