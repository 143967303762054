import EmptyContent from "components/base/EmptyContent";
import { useBackground } from "components/base/layout/Background";
import Media from "components/base/Media";
import Modal from "components/base/Modal";
import Table, { TBody, TD, TH, THead, TR } from "components/base/Table";
import Heading1 from "components/base/text/Heading1";
import Select from "components/form/Select/Select";
import useCoachPromptsets from "hooks/prompts/useCoachPromptsets";
import usePrompts from "hooks/prompts/usePrompts";
import { ComponentProps, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

export default function PromptsetsPage() {
  useBackground("rgb(102,77,243)", true);
  const { setid } = useParams<{ setid: string }>();
  const { promptsets, isPromptsetsLoading } = useCoachPromptsets();
  const { prompts } = usePrompts(setid);
  const navigate = useNavigate();
  const [image, setImage] = useState("");

  useEffect(() => {
    if (!setid && promptsets?.length) {
      navigate(window.location.pathname + "/" + promptsets[0].id, { replace: true });
    }
  }, [setid, promptsets, navigate]);

  if (!promptsets?.length && !isPromptsetsLoading) return <EmptyContent />;

  return (
    <Page>
      <Toolbar>
        <Select
          value={setid || ""}
          onChange={(e: any) => navigate(`/promptsets/${e.target.value}`)}
        >
          {promptsets?.sort().map((set: any) => (
            <option key={set.id} value={set.id}>
              {set.name}
            </option>
          ))}
        </Select>
      </Toolbar>
      <Header>
        <span>{promptsets?.find((set: any) => set.id === setid)?.name}</span>
        <Heading1>Prompt Set</Heading1>
      </Header>

      <TableLimiter>
        <Table>
          <THead>
            <TR>
              <TH>Prompt Name</TH>
              <TH>Greeting</TH>
              <TH>Prompt</TH>
              <TH>Peer Response</TH>
              <TH>Peer Name</TH>
            </TR>
          </THead>
          <TBody>
            {prompts?.map((item: any, i: number) => (
              <TR key={item.id}>
                <TD>
                  <Bold>{item.name}</Bold>
                  <ImageModalClickable onClick={() => setImage(item.image)} />
                </TD>
                <TD>{item.greeting}</TD>
                <TD>
                  <Bold>{item.messageGameStarted}</Bold>
                </TD>
                <TD>{item.defaultCoachResponse}</TD>
                <TD>{item.primeCoachName}</TD>
              </TR>
            ))}
          </TBody>
        </Table>
      </TableLimiter>
      <Modal visible={!!image} onBackdropClick={() => setImage("")}>
        <Media uri={image} />
      </Modal>
    </Page>
  );
}

const Bold = styled.span`
  font-weight: bold;
`;

const Page = styled.div`
  padding-top: 4rem;
`;

const WidthLimiter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
  max-width: 1280px;
  min-width: 960px;
  padding: 1rem;
`;

const Toolbar = styled.div`
  left: 1rem;
  position: absolute;
  top: 1.25rem;
  z-index: 1;
  & > div {
    max-width: calc(100vw - 7rem);
    width: 300px;
  }
`;

const Header = styled.div`
  align-items: center;
  color: #f9f7f4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0;
`;

const TableLimiter = styled(WidthLimiter)`
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ImageModalClickable = (props: ComponentProps<"div">) => (
  <div {...props} style={{ cursor: "pointer" }}>
    <svg viewBox="0 0 24 24" width="24" height="24" fill="#664df3">
      <path d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z"></path>
    </svg>
  </div>
);
