import Fade from "components/animations/Fade";
import Modal from "components/base/Modal";
import Small from "components/base/text/Small";
import Button, { BorderlessButton, SubmitButton } from "components/form/Button";
import ButtonGroup from "components/form/ButtonGroup";
import EmailInput from "components/form/EmailInput";
import Field from "components/form/Field";
import Input from "components/form/Input";
import PhoneInput from "components/form/PhoneInput";
import TimezoneSelect from "components/form/TimezoneSelect";
import Toggle from "components/form/Toggle";
import { Form, Formik, useFormikContext } from "formik";
import useUserExists from "hooks/useCheckUserExist";
import { PronoumsSelect } from "pages/Register/steps/PronoumsStep";
import { ComponentProps, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { TProfile } from "types/user";
import { emailValidation, phoneValidation } from "utils/validation";
import * as yup from "yup";

import Verification from "../Verification";

interface ProfileFormProps extends ComponentProps<typeof Formik> {
  loading?: boolean;
  initialValues: TProfile;
}

export default function ProfileForm({ loading, ...props }: ProfileFormProps) {
  const validationSchema = yup.object({
    firstName: yup.string().required("Name is required").max(32),
    lastName: yup.string().required("Last Name is required").max(32),
    phone: phoneValidation.test(
      "isVerified",
      "Phone not verified",
      (value, context) => {
        const isModified = value !== props.initialValues.phone;
        return !value || !(isModified && !context.parent.phoneVerification);
      },
    ),
    email: emailValidation.test(
      "isVerified",
      "Email not verified",
      (value, { parent }) =>
        value !== props.initialValues.email ? !!parent.emailVerification : true,
    ),
    parentsEmail: emailValidation,
    pronouns: yup.string().required("Required field").typeError("Required field"),
  });

  return (
    <Formik {...props} validationSchema={validationSchema}>
      {({ values, initialValues }) => (
        <Form>
          <FormGroup>
            <Field
              label="First Name"
              as={Input}
              placeholder="Enter name"
              name="firstName"
            />
            <div>
              <Field
                as={Input}
                label="Last Name *"
                placeholder="Enter last name"
                name="lastName"
              />
              <Small>
                * Note: For privacy, consider using only the first letter of your last
                name
              </Small>
            </div>
          </FormGroup>

          <FormGroup>
            <div>
              <FormGroup>
                <EmailFieldWithVerification />
              </FormGroup>
              <Field
                as={Toggle}
                label="Receive prompts via Email"
                name="notificationReceiveEmail"
              />
            </div>
            <div>
              <FormGroup>
                <PhoneFieldWithVerification />
              </FormGroup>
              <Field
                as={Toggle}
                label="Receive prompts via SMS"
                name="notificationReceiveSMS"
                disabled={!initialValues.phone}
              />
              <Field
                as={Toggle}
                label="Receive prompts via Whatsapp"
                name="notificationReceiveWhatsapp"
                disabled={!initialValues.phone}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <Field as={TimezoneSelect} name="timezone" label="Timezone" />
            <Field as={PronoumsSelect} name="pronouns" label="Pronouns" />
          </FormGroup>
          <ButtonGroup>
            <SubmitButton loading={loading}>Save</SubmitButton>
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  );
}

const EmailFieldWithVerification = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { values, initialValues, setFieldValue, errors, setFieldError } =
    useFormikContext<TProfile>();
  const isModified = values.email !== initialValues.email;
  const needsVerification =
    !values.emailVerification && isModified && errors.email === "Email not verified";
  const { userExistsRefetch, isUserExistsLoading } = useUserExists({
    email: values.email,
  });

  useEffect(() => {
    if (isModified && values.emailVerification) {
      values.email && setFieldValue("emailVerification", undefined);
    }
  }, [values.email, isModified, setFieldValue, values.emailVerification]);

  const handleVerificationComplete = (code: string) => {
    setFieldValue("emailVerification", code);
    setIsModalOpen(false);
  };

  const handleVerificationStart = async () => {
    try {
      const { data } = await userExistsRefetch();
      if (!data?.data.exists) {
        setIsModalOpen(true);
      } else {
        setFieldError("email", "Email already in use");
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <VerificationModal visible={isModalOpen}>
        <Verification email={values.email} onComplete={handleVerificationComplete} />
        <BorderlessButton onClick={() => setIsModalOpen(false)}>Back</BorderlessButton>
      </VerificationModal>
      <VerifiyFieldContainer>
        <Field
          as={StyledEmailInput}
          label="Email"
          name="email"
          needsVerification={needsVerification}
        />
        <Fade visible={needsVerification}>
          <VerifyButton onClick={handleVerificationStart} loading={isUserExistsLoading}>
            Verify
          </VerifyButton>
        </Fade>
      </VerifiyFieldContainer>
    </div>
  );
};

const PhoneFieldWithVerification = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { values, initialValues, setFieldValue, errors, setFieldError } =
    useFormikContext<TProfile>();
  const isModified = values.phone !== initialValues.phone;
  const needsVerification =
    !values.phoneVerification && isModified && errors.phone === "Phone not verified";
  const { userExistsRefetch, isUserExistsLoading } = useUserExists({
    phone: values.phone,
  });

  useEffect(() => {
    values.phone && isModified && setFieldValue("phoneVerification", undefined);
  }, [values.phone, isModified, setFieldValue]);

  const handleVerificationComplete = useCallback(
    (code: string) => {
      setFieldValue("phoneVerification", code);
      setIsModalOpen(false);
    },
    [setFieldValue],
  );

  const handleVerificationStart = async () => {
    try {
      const { data } = await userExistsRefetch();
      if (!data?.data.exists) {
        setIsModalOpen(true);
      } else {
        setFieldError("phone", "Phone already in use");
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <VerificationModal visible={isModalOpen}>
        <Verification phone={values.phone} onComplete={handleVerificationComplete} />
        <BorderlessButton onClick={() => setIsModalOpen(false)}>Back</BorderlessButton>
      </VerificationModal>
      <VerifiyFieldContainer>
        <Field
          as={PhoneInput}
          label="Phone Number"
          name="phone"
          placeholder="Enter phone number"
        />
        <Fade visible={!!needsVerification}>
          <VerifyButton onClick={handleVerificationStart} loading={isUserExistsLoading}>
            Verify
          </VerifyButton>
        </Fade>
      </VerifiyFieldContainer>
    </div>
  );
};

const StyledEmailInput = styled(EmailInput)<{ needsVerification?: boolean }>`
  input {
    padding-right: ${({ needsVerification }) => (needsVerification ? "4rem" : "0")};
  }
`;

const VerifiyFieldContainer = styled.div`
  position: relative;
`;

const VerifyButton = styled(Button)`
  position: absolute;
  right: 0.5rem;
  top: 1.95rem;
  font-size: 0.75rem;
  padding: 0.5rem;
}`;

const FormGroup = styled.div`
  @media (max-width: 767px) {
    margin-bottom: 0.5rem;
    & > div {
      margin-bottom: 0.5rem;
    }
  }
  @media (min-width: 768px) {
    margin-bottom: 0.5rem;
    display: flex;
    gap: 1rem;
    & > div {
      flex: 1;
    }
  }
`;

const VerificationModal = styled(Modal)`
  width: 320px;
`;
