import Card from "components/base/Box";
import { ComponentProps } from "react";
import styled from "styled-components";
import { RackStack as RackStackType } from "types/analytics";

interface RackStackProps extends ComponentProps<typeof Card> {
  data: RackStackType;
}

export default function RackStack({ data, ...props }: RackStackProps) {
  return (
    <Card title="Rack & Stack" subtitle="How do you impact others?" {...props}>
      <RackStackContent>
        <RackItem>
          <img src={`/images/${data.value1.icon}.svg`} alt={data.value1.header} />
          <div>
            <>{data.value1.header}</>
            <p>{data.value1.body}</p>
          </div>
        </RackItem>
        <RackItem>
          <img src={`/images/${data.value2.icon}.svg`} alt={data.value2.header} />
          <div>
            <>{data.value2.header}</>
            <p>{data.value2.body}</p>
          </div>
        </RackItem>
        <RackItem>
          <img src={`/images/${data.value3.icon}.svg`} alt={data.value3.header} />
          <div>
            <>{data.value3.header}</>
            <p>{data.value3.body}</p>
          </div>
        </RackItem>
      </RackStackContent>
    </Card>
  );
}

const RackStackContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  max-width: 100%;
  min-width: 260px;
  width: 300px;
`;

const RackItem = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 1rem;
  padding: 0.5rem 0;
  img {
    height: 4.4rem;
    width: 4.4rem;
  }
`;
