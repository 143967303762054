import Heading1 from "components/base/text/Heading1";
import Heading2 from "components/base/text/Heading2";
import { Footer } from "components/Footer";
import JourneyHeader from "components/Journey/JourneyHeader";
import JourneyList from "components/Journey/JourneyList";
import { useGetUserGames } from "hooks/useGetUserGames";
import { useGetUserJourney } from "hooks/useGetUserJourney";
import useProfile from "hooks/user/useProfile";
import styled from "styled-components";

export const HomePage = () => {
  const { journey, isJourneyLoading } = useGetUserJourney();
  const { games } = useGetUserGames();
  const lastOpenCycle = games?.data?.[0];
  const { profile } = useProfile();

  return (
    <div>
      <Greeting>
        <Heading1>{greetingText()},</Heading1>
        <Heading2>{profile?.firstName}</Heading2>
      </Greeting>
      {!!lastOpenCycle && !!journey?.length && <JourneyHeader cycle={lastOpenCycle} />}
      <JourneyList journey={journey} isLoading={isJourneyLoading} />
      <Footer />
    </div>
  );
};

const Greeting = styled.div`
  color: #f9f7f4;
  margin: 0 auto;
  padding: 3rem 1rem 0;
  width: 420px;
`;

function greetingText() {
  const currentHour = new Date().getHours(); // Get the current hour (0-23)

  if (currentHour >= 5 && currentHour < 12) {
    return "Good morning";
  } else if (currentHour >= 12 && currentHour < 18) {
    return "Good afternoon";
  } else {
    return "Good evening";
  }
}
