import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import useScrollAction from "hooks/utils/useScrollAction";
import { apiRoute, commonHeaders } from "utils/api";

export default function usePrompts(setid?: string) {
  const {
    data,
    isFetching: isPromptsLoading,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: ["usePrompts", setid],

    queryFn: ({ pageParam = 1 }) =>
      axios.get<any>(`${apiRoute}/prompt`, {
        params: {
          page: pageParam,
          limit: 10,
          setId: setid,
        },
        ...commonHeaders(),
      }),
    getNextPageParam: lastPage => {
      return lastPage.data.pagination.total / 10 > lastPage.data.pagination.page
        ? lastPage.data.pagination.page + 1
        : undefined;
    },
    initialPageParam: 1,
  });

  useScrollAction({ action: fetchNextPage });

  const prompts = data?.pages.map(page => page.data.data).flat();

  return { prompts, isPromptsLoading };
}
