import { MessageTransport, UserProfile } from "@dailyhuman/web-core/dist/api/user";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { SnackBarContext } from "components/base/Alert/Alert";
import { useContext, useState } from "react";
import { TProfile } from "types/user";
import { apiRoute, commonHeaders, queryClient } from "utils/api";

type ProfileUpdateError = {
  error: {
    message: string;
    fields?: { field: string; message: string }[];
  };
};

export default function useProfileUpdate() {
  const [updateProfileErrors, setUpdateProfileErrors] = useState<{
    [key: string]: string;
  }>({});
  const context = useContext(SnackBarContext);
  const { mutate: updateProfile, isPending: isProfileUpdating } = useMutation({
    mutationFn: (data: Partial<TProfile>) =>
      axios.put<Partial<UserProfile>>(
        `${apiRoute}/user/me`,
        mapProfileToRequest(data),
        commonHeaders(),
      ),
    onSuccess: async () => {
      context?.setSnackBar({
        status: "success",
        text: "Profile was updated",
      });
      await queryClient.invalidateQueries({ queryKey: ["getProfile"] });
    },
    onError: (error: AxiosError<ProfileUpdateError>) => {
      setUpdateProfileErrors(mapProfileErrors(error) || {});
      context?.setSnackBar({
        status: "failure",
        text: error.response?.data.error.message || "Error updating profile",
      });
    },
  });

  return { updateProfile, isProfileUpdating, updateProfileErrors };
}

const { EMAIL, SMS, WHATSAPP } = MessageTransport;

const mapProfileToRequest = (data: Partial<TProfile>): Partial<UserProfile> => ({
  email: data.email,
  phone: data.phone || undefined,
  firstName: data.firstName,
  lastName: data.lastName,
  timezone: data.timezone,
  isAdult: data.isAdult,
  notificationMethod: [
    ...(data.notificationReceiveEmail ? [EMAIL] : []),
    ...(data.notificationReceiveSMS ? [SMS] : []),
    ...(data.notificationReceiveWhatsapp ? [WHATSAPP] : []),
  ] as (MessageTransport.EMAIL | MessageTransport.SMS | MessageTransport.WHATSAPP)[],
  parentsEmail: data.parentsEmail || undefined,
  pronouns: data.pronouns || undefined,
});

const mapProfileErrors = (error: AxiosError<ProfileUpdateError>) =>
  error.response?.data.error.fields?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.field === "notificationMethod" ? "notificationReceiveEmail" : curr.field]:
        curr.message,
    }),
    {},
  );
