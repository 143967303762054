import { createRoot } from "react-dom/client";
import ReactGA from "react-ga4";
import { sentryInit } from "utils/sentry";

import App from "./App";

const { hostname } = window.location;

if (!hostname.includes("localhost")) {
  sentryInit();
  ReactGA.initialize("G-XH5JVDYRLM");
}

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<App />);
