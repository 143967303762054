import styled from "styled-components";

const Heading3 = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
`;

export default Heading3;
