import { isObjectLike } from "lodash";

export enum BrowserStorageItem {
  AUTH_TOKEN = "authToken",
  LAB_MESSAGE_INPUT_VALUE = "labMessageInputValue",
  REDIRECT_TIME = "redirectTime",
}

class StorageService {
  private storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  get(itemName: BrowserStorageItem): string | null {
    let item;
    try {
      item = this.storage.getItem(itemName);
    } catch (error) {
      console.error(`getItem from storage error: ${error}`);
    }
    const numPattern = new RegExp(/^\d+$/);
    const jsonPattern = new RegExp(/[[{].*[}\]]/);

    if (item) {
      if (jsonPattern.test(item)) {
        return JSON.parse(item);
      }
      if (numPattern.test(item)) {
        return parseFloat(item).toString();
      }
      return item;
    }
    return null;
  }

  set(itemName: BrowserStorageItem, item: any): void {
    try {
      if (isObjectLike(item)) {
        this.storage.setItem(itemName, JSON.stringify(item));
      } else {
        this.storage.setItem(itemName, item);
      }
      window.dispatchEvent(new Event("storage"));
    } catch (error) {
      console.error(`setItem to storage error: ${error}`);
    }
  }

  remove(itemName: BrowserStorageItem): void {
    this.storage.removeItem(itemName);
  }
}
export const localStorageService = new StorageService(localStorage);
export const sessionStorageService = new StorageService(sessionStorage);

export default StorageService;
