import { useRive } from "@rive-app/react-canvas";

import AnimationFile from "./Animation.riv";

const stateMachine = "State Machine 1";

type SendButtonProps = {
  isDisabled?: boolean;
};

export default function Animation({ isDisabled }: SendButtonProps) {
  const { RiveComponent } = useRive({
    src: AnimationFile,
    stateMachines: stateMachine,
    autoplay: true,
    shouldDisableRiveListeners: true,
  });

  //const loop = useStateMachineInput(rive, stateMachine, "Boolean 1");

  return <RiveComponent />;
}
