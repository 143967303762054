import Button from "components/form/Button";
import ButtonGroup from "components/form/ButtonGroup";
import { useSendMagicLink } from "hooks/useSendMagicLink";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { useOnboardingContext } from "../components/OnboardingUserFlow";
import { useRegisterFormContext } from "../components/RegisterForm";

export const UserExistsStep = () => {
  const { sendMagicLink } = useSendMagicLink();
  const { setMainStep } = useOnboardingContext() || {};
  const { values } = useRegisterFormContext();
  const { poolSlug } = useParams<{ poolSlug: string }>();

  const handleClick = () => {
    sendMagicLink({
      redirectTo: `/onboarding/${poolSlug}?skipintro=true`,
      ...(values.phone && { sms: values.phone }),
      ...(values.email && { email: values.email }),
    });
    setMainStep?.("CheckEmailStep");
  };

  return (
    <Container>
      <div>
        <h2>You already have an account!</h2>
        <span>
          Your access link will be sent to <b>{values.email || `+${values.phone}`}</b>
        </span>
      </div>
      <ButtonGroup>
        <Button type={"button"} onClick={handleClick}>
          Send link
        </Button>
      </ButtonGroup>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  text-align: center;
  h2 {
    font-size: 1.375rem;
    font-weight: 500;
    margin-bottom: 2rem;
  }
  b {
    font-weight: 600;
  }
  span {
    display: block;
    margin-bottom: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
