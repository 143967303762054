import { ErrorMessage as ErrorMessageContent } from "formik";
import { ComponentProps, ReactNode } from "react";
import styled from "styled-components";

interface ErrorMessageProps extends ComponentProps<typeof ErrorMessageComponent> {
  name?: string;
  children?: ReactNode;
}

const ErrorMessage = (props: ErrorMessageProps) => {
  return (
    <ErrorMessageComponent {...props}>
      {props.name && <ErrorMessageContent name={props.name} />}
      {props.children}‎
    </ErrorMessageComponent>
  );
};

const ErrorMessageComponent = styled.p`
  color: #ff0000;
  font-size: 0.75rem;
  margin-left: 1rem;
  margin-top: 0.25rem;
`;

export default ErrorMessage;
