import { useEffect, useState } from "react";

const useWidth = (ref: React.RefObject<HTMLDivElement> | undefined = undefined) => {
  const [width, setWidth] = useState(ref?.current?.offsetWidth || window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(ref?.current?.offsetWidth || window.innerWidth);
    handleResize();
    const interval = setInterval(handleResize, 1000);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(interval);
    };
  }, [ref]);

  return width;
};

export default useWidth;
