import { useMutation } from "@tanstack/react-query";
import axios, { AxiosHeaders, AxiosResponse } from "axios";

import { apiRoute, commonHeaders } from "../utils/api";

type EmailVerificationReqType = {
  code: string;
};

function useConfirmTransport() {
  const {
    mutate: sendConfirmationRequest,
    isPending: isConfirmationRequestLoading, // Changed from isLoading to isPending
    data: confirmationResponse,
    status,
    reset,
  } = useMutation({
    mutationKey: ["confirmVerification"],
    mutationFn: (data: EmailVerificationReqType) =>
      axios
        .post<
          EmailVerificationReqType,
          { data: { confirmed: boolean } }
        >(`${apiRoute}/user/verification/confirm`, data, commonHeaders())
        .catch(() => {
          const dataOnError: AxiosResponse<{ confirmed: boolean }> = {
            data: { confirmed: false },
            status: 200,
            statusText: "Ok",
            headers: commonHeaders().headers,
            config: {
              headers: {} as AxiosHeaders,
            },
          };
          return dataOnError;
        }),
  });

  return {
    sendConfirmationRequest,
    isConfirmationRequestLoading,
    confirmationResponse,
    status,
    reset,
  };
}

export { useConfirmTransport };
