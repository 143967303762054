import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import init from "./init";

const SCROLL_MULT = 7;

type BackgroundProps = {
  speed: number;
  color: string;
};

export default function AnimatedBackground({ speed, color }: BackgroundProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const updateRef = useRef<Function>();
  const [currentSpeed, setCurrentSpeed] = useState(speed);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    let lastScrollY: number;

    const handleScroll = () => {
      clearTimeout(timeout);
      const scrollDeltaY = window.scrollY - lastScrollY;
      lastScrollY = window.scrollY;
      timeout = setTimeout(() => setCurrentSpeed(speed), 100);
      scrollDeltaY && setCurrentSpeed(scrollDeltaY * SCROLL_MULT);
    };

    const throttle = <T extends any[]>(fn: (...args: T) => void) => {
      let wait = false;
      return (...args: T) => {
        if (!wait) {
          fn(...args);
          wait = true;
          setTimeout(() => (wait = false), 30);
        }
      };
    };

    const throttledScroll = throttle(handleScroll);
    window.addEventListener("scroll", throttledScroll);
    return () => {
      window.removeEventListener("scroll", throttledScroll);
    };
  }, [speed]);

  useEffect(() => {
    setCurrentSpeed(speed);
  }, [speed]);

  useEffect(() => {
    if (!canvasRef.current) return;
    const { destroy, update } = init(canvasRef.current);
    updateRef.current = update;
    return destroy;
  }, []);

  useEffect(() => {
    if (updateRef.current) {
      updateRef.current({ speed: currentSpeed });
    }
  }, [currentSpeed]);

  return (
    <>
      <BackgroundColor background={color} className="AnimatedBackgroundColor" />
      <Wrapper className="AnimatedBackgroundWrapper">
        <Content>
          <Canvas ref={canvasRef} />
        </Content>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  background: lightblue;
  height: 100vh;
  left: 0;
  mix-blend-mode: screen;
  position: fixed;
  top: 0;
  width: 100vw !important;
  z-index: -1;
`;

const BackgroundColor = styled.div<{ background: string }>`
  background: ${props => props.background};
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  transition: background 0.6s ease-out;
  width: 100vw;
  z-index: -2;
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
`;

const Canvas = styled.canvas`
  height: 100%;
  width: 100%;
`;
