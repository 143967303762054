import countries from "constants/countries";
import { PhoneNumberUtil } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();

export default function parsePhoneNumber(
  phoneNumber: string,
): {
  countryCallingCode?: number;
  nationalNumber?: number;
  countryName?: string;
  iso2Code?: string;
} {
  try {
    const parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumber);
    const countryCallingCode = parsedNumber.getCountryCode();
    const nationalNumber = parsedNumber.getNationalNumber();

    const countryName = findCountry(
      countryCallingCode?.toString(),
      nationalNumber?.toString(),
    );

    return { countryCallingCode, nationalNumber, ...countryName };
  } catch (error) {
    return { countryCallingCode: undefined, nationalNumber: undefined };
  }
};

const findCountry = (
  countryCode?: string,
  phoneNumber?: string,
): { countryName: string; iso2Code: string } | undefined => {
  if (!countryCode || !phoneNumber) return undefined;

  const candidatesCountries = countries.filter(
    item => item.dialCode === countryCode,
  );

  if (!candidatesCountries || candidatesCountries.length === 0) {
    return undefined;
  }

  candidatesCountries.sort((a, b) => (a.priority || 0) - (b.priority || 0));

  const countryWithAreaCode = candidatesCountries.find(item =>
    item.areaCodes?.some(areaCode => phoneNumber.startsWith(areaCode)),
  );

  const resultCountry = countryWithAreaCode || candidatesCountries[0];

  return {
    countryName: resultCountry.name,
    iso2Code: resultCountry.iso2,
  };
};