import styled from "styled-components";

const Heading1 = styled.h1`
  font-size: 1.875rem;
  font-weight: bold;
  @media (min-width: 768px) {
    font-size: 2.25rem;
  }
`;

export default Heading1;
