import Heading2 from "components/base/text/Heading2";
import Small from "components/base/text/Small";
import dayjs from "dayjs";
import styled from "styled-components";

type JourneyItemProps = {
  headline?: string | React.ReactNode;
  message?: string | React.ReactNode;
  sender?: string | React.ReactNode;
  date?: Date | string;
};

export default function JourneyItem({
  headline,
  message,
  sender,
  date,
}: JourneyItemProps) {
  const formatedDate = dayjs(date).format("MMMM D, YYYY");
  return (
    <MainWrapper>
      <Heading2>{headline || `${sender}'s Reflection`}</Heading2>
      <Text>{message}</Text>
      <div>
        <Author>{sender}</Author>
      </div>
      {date && <DateWrapper>{formatedDate}</DateWrapper>}
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  background: #f9f7f4;
  border-radius: 36px;
  color: #241f18;
  padding: 1.5rem;
  @media (min-width: 768px) {
    border-radius: 48px;
    padding: 2rem;
  }
`;

const Text = styled.p`
  margin: 1em 0;
  white-space: pre-line;
  word-break: break-word;
  a {
    color: #664df3;
  }
`;

const Author = styled(Small)`
  font-weight: 600;
  text-transform: uppercase;
`;

const DateWrapper = styled(Small)`
  color: #bfae96;
  font-weight: 600;
  text-transform: uppercase;
  > span {
    margin-left: 8px;
  }
`;
