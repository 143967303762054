import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { SnackBarContext } from "components/base/Alert/Alert";
import { useContext } from "react";
import { apiRoute, commonHeaders } from "utils/api";

type VerificationReqType = {
  type: VerificationType;
  destination: string;
};

export type VerificationType = "EMAIL" | "SMS" | "WHATSAPP";

function useVerifyTransport() {
  const context = useContext(SnackBarContext);

  const { mutate: sendVerificationRequest, isPending: isVerificationRequestLoading } =
    useMutation({
      mutationFn: (data: VerificationReqType) =>
        axios.post<VerificationReqType>(
          `${apiRoute}/user/verification/request`,
          data,
          commonHeaders(),
        ),
      onError: (error: AxiosError) => {
        context?.setSnackBar({
          status: "failure",
          text: "Error sending verification code.", // error.response?.data.error.message,
        });
      },
    });

  return {
    sendVerificationRequest,
    isVerificationRequestLoading,
  };
}

export { useVerifyTransport };
