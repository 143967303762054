import { IPageableResponse } from "@dailyhuman/web-core/dist/api/common";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { apiRoute, commonHeaders } from "../utils/api";

type Game = {
  id: string;
  name: string;
  price: number | null;
  nextBilledDate: string;
  createdAt: string;
};

export default function useGames() {
  const { data, isFetching: isGamesLoading } = useQuery({
    queryKey: ["useGames"],
    queryFn: () =>
      axios.get<IPageableResponse<Game>>(`${apiRoute}/game/user-active-games`, {
        ...commonHeaders(),
      }),
  });

  return { games: data?.data?.data, isGamesLoading };
}
