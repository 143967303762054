import { IListJourneyV3ResItem } from "@dailyhuman/web-core/dist/api/game/res";
import Fade from "components/animations/Fade";
import { useBackground } from "components/base/layout/Background";
import { ReactComponent as Labs } from "components/base/Menu/icons/Labs.svg";
import Heading2 from "components/base/text/Heading2";
import Button from "components/form/Button";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";

import { ReactComponent as Contact } from "./Contact.svg";
import IntersectionObserver from "./IntersectionObserver";
import JourneyListItem from "./JourneyListItem";
import { SectionSmall } from "./JourneySection";

export type JourneyListProps = {
  journey?: IListJourneyV3ResItem[];
  isLoading?: boolean;
  onLoadMore?: () => void;
};

export default function JourneyList({
  journey,
  isLoading,
  onLoadMore,
}: JourneyListProps) {
  const [intersecting, setIntersecting] = useState(0);

  const handleIntersect = (index: number) => {
    intersecting !== index && setIntersecting(index);
  };

  useBackground("rgb(102,77,243)", true);

  return (
    <JourneyListContainer className="JourneyList">
      <Helmet>
        <meta name="theme-color" content={"rgb(102,77,243)"} />
      </Helmet>
      <ContentContainer>
        {journey?.map((cycle, l) => (
          <IntersectionObserver onIntersection={() => {}} key={cycle.game.id}>
            <Fade delay={100}>
              <JourneyListItem cycle={cycle} />
            </Fade>
          </IntersectionObserver>
        ))}
        {onLoadMore && (
          <IntersectionObserver onIntersection={onLoadMore}>
            <LoadMoreButton onClick={onLoadMore}>Load more</LoadMoreButton>
          </IntersectionObserver>
        )}
        {!onLoadMore && !isLoading && Array.isArray(journey) && (
          <IntersectionObserver onIntersection={() => handleIntersect(journey.length)}>
            <Fade delay={100}>
              {journey.length === 0 && (
                <SectionSmall>
                  <Heading2>Answer the prompt</Heading2>
                  <a href="/labs">
                    <Button icon={Labs}>Answer the prompt</Button>
                  </a>
                </SectionSmall>
              )}
              <SectionSmall>
                <Heading2>Have you saved Dailyhuman's contact card?</Heading2>
                <a href="https://dh.live/save-contact">
                  <Button icon={Contact}>Save contact</Button>
                </a>
              </SectionSmall>
            </Fade>
          </IntersectionObserver>
        )}
      </ContentContainer>
    </JourneyListContainer>
  );
}

const JourneyListContainer = styled.div`
  min-height: calc(100dvh - 60px);
  position: relative;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 6rem 0rem 2rem 0rem;
`;

const LoadMoreButton = styled.button`
  background: #f9f7f4;
  border: none;
  border-radius: 50%;
  color: #676464;
  cursor: pointer;
  display: block;
  font-weight: 800;
  height: 6rem;
  margin: 54px auto 0 auto;
  outline: none;
  text-transform: uppercase;
  transition: all 0.3s;
  width: 6rem;
  :hover {
    transform: translateY(-0.5rem);
  }
`;
