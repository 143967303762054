import Card from "components/base/Box";
import { ComponentProps } from "react";
import styled from "styled-components";
import { ConnectingDots as ConnectingDotsType } from "types/analytics";

interface ConnectingDotsProps extends ComponentProps<typeof Card> {
  data: ConnectingDotsType;
}

export default function ConnectingDots({ data, ...props }: ConnectingDotsProps) {
  return (
    <Card
      title="Connecting the Dots"
      subtitle="Show me, Don't just tell me."
      {...props}
    >
      <ConnectingDotsContent>
        <ConnectingDotsItem>
          <img src={`/images/${data.strength.icon}.svg`} alt="Strength Icon" />
          <div>
            <>Strength</>
            {data.strength.body}
          </div>
        </ConnectingDotsItem>
        <ConnectingDotsItem>
          <img src={`/images/${data.opportunity.icon}.svg`} alt="Opportunity Icon" />
          <div>
            <>Opportunity</>
            {data.opportunity.body}
          </div>
        </ConnectingDotsItem>
        <ConnectingDotsItem>
          <img src={`/images/${data.weakness.icon}.svg`} alt="Weakness Icon" />
          <div>
            <>Weakness</>
            {data.weakness.body}
          </div>
        </ConnectingDotsItem>
        <ConnectingDotsItem>
          <img src={`/images/${data.threats.icon}.svg`} alt="Threats Icon" />
          <div>
            <>Threats</>
            {data.threats.body}
          </div>
        </ConnectingDotsItem>
      </ConnectingDotsContent>
    </Card>
  );
}

const ConnectingDotsContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-around;
  max-width: 100%;
  min-width: 260px;
  width: 300px;
`;

const ConnectingDotsItem = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  img {
    height: 4.4rem;
    width: 4.4rem;
  }
`;
