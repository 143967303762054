import { ComponentProps, useEffect, useState } from "react";
import styled from "styled-components";

interface ModalProps extends ComponentProps<typeof Content> {
  onBackdropClick?: () => void;
  visible?: boolean;
}

export default function Modal({
  children,
  onBackdropClick,
  visible = true,
  ...props
}: ModalProps) {
  const [isDisplayed, setIsDisplayed] = useState(visible);
  const [isLoaded, setIsLoaded] = useState(visible);

  useEffect(() => {
    if (visible && !isLoaded) setIsLoaded(true);
    setTimeout(() => setIsDisplayed(visible), 0);
  }, [visible, isLoaded]);

  return (
    <>
      <Backdrop onClick={onBackdropClick} visible={visible} />
      {isLoaded && (
        <Content visible={isDisplayed} {...props}>
          {children}
        </Content>
      )}
    </>
  );
}

const Backdrop = styled.div<{ visible?: boolean }>`
  align-items: center;
  background: rgba(232, 232, 232, 0.5);
  display: flex;
  height: 100dvh;
  justify-content: center;
  left: 0;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? "auto" : "none")};
  position: fixed;
  top: 0;
  transition: all 0.3s ease-in-out;
  width: 100dvw;
  z-index: 100;
  @media (min-width: 768px) {
    backdrop-filter: blur(2px);
  }
`;

const Content = styled.div<{ visible?: boolean }>`
  background: #eee7df;
  border-radius: 24px;
  box-shadow: 0px 1px 16px rgba(44, 44, 44, 0.15);
  left: 50%;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  padding: 20px;
  pointer-events: ${({ visible }) => (visible ? "auto" : "none")};
  position: fixed;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out 0.1s;
  z-index: 101;
`;
