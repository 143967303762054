import Fade from "components/animations/Fade";
import styled from "styled-components";

import { ReactComponent as EmptyIcon } from "./EmptyContent.svg";
import Heading1 from "./text/Heading1";

type EmptyContentProps = {
  title?: string;
  subtitle?: string;
};

export default function EmptyContent({
  title = "Sit tight ;)",
  subtitle = "This content is not available yet.",
}: EmptyContentProps) {
  return (
    <Content>
      <Fade delay={50}>
        <Icon />
        <Heading1>{title}</Heading1>
        <Subtitle>{subtitle}</Subtitle>
      </Fade>
    </Content>
  );
}

const Content = styled(Fade)`
  left: 50%;
  padding: 2rem;
  position: fixed;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;

const Icon = styled(EmptyIcon)`
  max-width: 100%;
`;

const Subtitle = styled.h2`
  margin: 1rem auto;
  max-width: 400px;
`;
