import { createContext, useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { ReactComponent as Failure } from "./Failure.svg";
import { ReactComponent as Success } from "./Success.svg";

export const SnackBarContext = createContext<{
  setSnackBar: React.Dispatch<
    React.SetStateAction<{
      text: string;
      status: "failure" | "success";
    } | null>
  >;
} | null>(null);

type SnackBarPropsType = {
  status?: "failure" | "success";
  text?: string;
};

export const SnackBarProvider = (props: any) => {
  const [content, setContent] = useState<{
    text: string;
    status: "failure" | "success";
  } | null>(null);

  return (
    <SnackBarContext.Provider value={{ setSnackBar: setContent }}>
      {props.children}
      <SnackBar status={content?.status} text={content?.text} />
    </SnackBarContext.Provider>
  );
};

export const SnackBar = ({ status, text }: SnackBarPropsType) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { setSnackBar } = useContext(SnackBarContext)!;

  useEffect(() => {
    if (status && text) {
      setIsVisible(true);
      const t1 = setTimeout(() => setIsVisible(false), 3000);
      const t2 = setTimeout(() => setSnackBar(null), 3500);

      return () => {
        clearTimeout(t1);
        clearTimeout(t2);
      };
    }
  }, [setSnackBar, status, text]);

  return (
    <Snack
      error={status === "failure"}
      isVisible={isVisible}
      onClick={() => setIsVisible(false)}
    >
      <span>{text}</span>
      {status === "failure" ? <Failure /> : <Success />}
    </Snack>
  );
};

const Snack = styled.div<{ error: boolean; isVisible: boolean }>`
  align-items: center;
  background: ${props => (props.error ? "#B81922" : "#664DF3")};
  border-radius: 99px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  left: 50%;
  min-height: 40px;
  min-width: 350px;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  padding: 0.5rem;
  padding-left: 1.5rem;
  position: fixed;
  top: 1.25rem;
  transform: translateX(-50%)
    ${props => (props.isVisible ? "translateY(0)" : "translateY(-150%)")};
  transition: all 0.3s ease-in-out;
  z-index: 10001;
  span {
    color: #fff;
  }
  img {
    margin-left: 1rem;
    width: 25px;
    :hover {
      cursor: pointer;
    }
  }
`;
