import { useEffect } from "react";

interface KeyActionProps {
  key: string;
  action: (e: KeyboardEvent) => void;
}

export default function useKeyAction({ key, action }: KeyActionProps) {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === key) action(e);
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [action, key]);

  return action;
}
