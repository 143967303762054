import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import useScrollAction from "hooks/utils/useScrollAction";
import { apiRoute, commonHeaders } from "utils/api";

type useEngagementReportOptions = {
  filter: {
    firstName?: string;
    lastName?: string;
    startDate?: string;
    endDate?: string;
  };
};

export default function useEngagementReport(
  gameid?: string,
  options?: useEngagementReportOptions,
) {
  const { filter = {} } = options || {};
  const {
    data,
    isFetching: isEngagementReportsLoading,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: ["useEngagementReports", gameid, filter],
    queryFn: ({ pageParam = 1 }) =>
      axios.get<any>(`${apiRoute}/engagement/game/${gameid}`, {
        params: {
          limit: 20,
          page: pageParam,
          sort: "-completion",
          user: {
            firstName: filter.firstName || undefined,
            lastName: filter.lastName || undefined,
          },
          labStart: {
            from: filter.startDate || undefined,
          },
          labEnd: {
            till: filter.endDate || undefined,
          },
        },
        ...commonHeaders(),
      }),
    getNextPageParam: lastPage => {
      return lastPage.data.pagination.total / 20 > lastPage.data.pagination.page
        ? lastPage.data.pagination.page + 1
        : undefined;
    },
    initialPageParam: 1,
    enabled: !!gameid,
  });

  useScrollAction({ action: fetchNextPage });

  const engagementReport = data?.pages.map(page => page.data.data).flat();

  return { engagementReport, isEngagementReportsLoading };
}
