import { IListGroupUserAnalyticsResV3 } from "@dailyhuman/web-core/dist/api/game/res";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { TAnalytics } from "types/analytics";
import { apiRoute, commonHeaders } from "utils/api";

export type TAnalyticsGroupUser = {
  user: {
    id: string;
    name: string;
  };
  analytics: TAnalytics;
};

export default function useAnalyticsGroupUsers({
  groupId,
  promptSetId,
}: {
  groupId?: string;
  promptSetId?: string;
}) {
  const { data: response, isFetching: isAnalyticsGroupUsersLoading } = useQuery({
    queryKey: ["useAnalyticsGroupUsers", groupId, promptSetId],
    queryFn: () =>
      axios.get<IListGroupUserAnalyticsResV3>(
        `${apiRoute?.slice(0, -3)}/v3/recognition/analytics/group/${groupId}/user`,
        {
          ...commonHeaders(),
          params: { promptSetId },
        },
      ),
    enabled: !!groupId, // Ensures the query only runs when groupId is not null or undefined
  });

  return { analyticsGroupUsers: response?.data.data, isAnalyticsGroupUsersLoading };
}
