//import { useLabTermination } from "hooks/labs/useLabTermination";
import {
  AnalyticsProcessingStatus,
  IListGameChatHistoryRes,
} from "@dailyhuman/web-core/dist/api/game";
import Accordion from "components/animations/Accordion";
import Switch from "components/animations/Switch";
import Loading from "components/base/Loading";
import Button from "components/form/Button";
import { Buckets } from "hooks/useGetUserGames";
import { usePostNewMessage } from "hooks/usePostNewMessage";
import { ComponentProps, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import RecordIcon from "./RecordIcon";
import TranscribeWidget from "./TranscribeWidget";

interface MessagingInputProps {
  game: Buckets;
  messages: IListGameChatHistoryRes;
}

const MessagingInput = ({ game, messages }: MessagingInputProps) => {
  const { addMessage } = usePostNewMessage();
  const [newMessage, setNewMessage] = useState("");
  const [showRecordWidget, setShowRecordWidget] = useState(false);

  const isLastMessageFromUser =
    (messages && messages.data[messages.data.length - 1]?.direction === "IN") || false;

  const limit = 1500;
  const remaining = limit - newMessage.length;
  const disabled = newMessage.trim() === "" || remaining < 0;

  const handleSendClick = (e: any) => {
    if (!disabled) {
      addMessage({
        cycleId: game.cycleId,
        recipientId: game.partner.id,
        message: newMessage,
      });
      setNewMessage("");
    }
  };

  const handleTranscribe = (transcription: string) => {
    setShowRecordWidget(false);
    setNewMessage(transcription);
  };

  const isCompleted =
    messages.meta?.lab?.analyticsStatus === AnalyticsProcessingStatus.COMPLETED;

  const renderContent = () => {
    if (showRecordWidget)
      return <TranscribeWidget message={newMessage} onTranscribe={handleTranscribe} />;
    if (isLastMessageFromUser)
      return (
        <LoadingContainer>
          <Loading />
          <div>
            {isCompleted ? "Unlocking your Mindset Card" : "Analyzing your reflection"}
          </div>
        </LoadingContainer>
      );
    return (
      <Wrapper>
        <InputFieldContainer>
          <InputFieldComponent
            placeholder="Type your reflection..."
            value={newMessage}
            onChange={(e: React.MouseEvent<HTMLTextAreaElement>) =>
              setNewMessage(e.currentTarget.value)
            }
          />
          <div onClick={() => setShowRecordWidget(true)} style={{ cursor: "pointer" }}>
            <RecordIcon />
          </div>
        </InputFieldContainer>
        <Accordion>
          {remaining < 500 && (
            <ErrorContainer remaining={remaining}>
              {newMessage.length} / {limit}
            </ErrorContainer>
          )}
        </Accordion>
        <SendButtonContainer>
          Unlock mindset card {"->"}
          <Button onClick={handleSendClick} disabled={disabled}>
            I'm done
          </Button>
        </SendButtonContainer>
      </Wrapper>
    );
  };

  return (
    <div className="MessagingInput">
      <Accordion>
        <Switch renderKey={isLastMessageFromUser + " " + showRecordWidget}>
          {renderContent()}
        </Switch>
      </Accordion>
    </div>
  );
};

export default MessagingInput;

const InputFieldComponent = (props: ComponentProps<typeof InputField>) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.style.transition = "none";
      const currentHeight = ref.current.clientHeight;
      ref.current.style.height = "1px";
      const scrollHeight = ref.current.scrollHeight;
      ref.current.style.height = currentHeight + "px";
      if (scrollHeight !== currentHeight) {
        setTimeout(() => {
          if (!ref?.current) return;
          ref.current.style.transition = "height 0.3s";
          ref.current.style.height = scrollHeight + "px";
        }, 1);
      }
    }
  }, [ref, props.value]);

  return <InputField {...props} ref={ref} />;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 0;
  position: relative;
`;

const InputFieldContainer = styled.div`
  align-items: flex-end;
  background: #fff;
  border: none;
  border-radius: 1.625rem;
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
`;

const InputField = styled.textarea`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  color: #241f18;
  font-family: inherit;
  font-size: 1.125rem;
  height: 2rem;
  line-height: 150%;
  max-height: 10rem;
  outline: none;
  overflow-y: scroll;
  resize: none;
  width: 100%;
  ::placeholder {
    color: #bfae96;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ErrorContainer = styled.div<{ remaining: number }>`
  bottom: 0;
  color: ${({ remaining }) => (remaining < 0 ? "#B81822" : "auto")};
  padding: 0rem 1rem;
  text-align: right;
  transition: all 0.3s;
`;

const SendButtonContainer = styled.div`
  align-items: center;
  color: #bfae96;
  display: flex;
  gap: 1rem;
  justify-content: end;
`;

const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 2rem;
  text-align: center;
`;
