import { ComponentProps } from "react";
import styled from "styled-components";

export function ArrowDown({ children, ...props }: ComponentProps<typeof Container>) {
  return (
    <Container {...props}>
      {children || (
        <svg
          width="12"
          height="7"
          viewBox="0 0 12 7"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
        >
          <path d="M1 2L6 6L11 2" />
        </svg>
      )}
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  background: #664df3;
  border-radius: 50%;
  display: flex;
  height: 1.75rem;
  justify-content: center;
  svg {
    stroke: white;
  }
  transition: transform 0.2s ease-in-out;
  width: 1.75rem;
`;

export const ArrowRight = styled(ArrowDown)`
  transform: rotate(-90deg);
`;
