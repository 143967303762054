import Fade from "components/animations/Fade";
import { BorderlessButton } from "components/form/Button";
import { useGetOnboardingGroupInfo } from "hooks/useGetOnboardingGroupInfo";
import useIsAuth from "hooks/user/useIsAuth";
import useKeyAction from "hooks/utils/useKeyAction";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { useOnboardingContext } from "../components/OnboardingUserFlow";
import { ReactComponent as WelcomeImage } from "../components/WelcomeImage.svg";

export default function InitialStep() {
  const isAuth = useIsAuth();
  const { poolSlug } = useParams<{ poolSlug: string }>();
  const { poolInfo } = useGetOnboardingGroupInfo(poolSlug);
  const { setMainStep } = useOnboardingContext() || {};

  const handleClick = useCallback(() => {
    if (isAuth) {
      setMainStep?.(poolInfo?.data.isPaid ? "PaymentStep" : "JoinLabStep");
    } else {
      setMainStep?.("PhoneStep");
    }
  }, [isAuth, setMainStep, poolInfo?.data.isPaid]);

  // effect that will attach enter on window to go to next step
  useKeyAction({ key: "Enter", action: handleClick });

  return (
    <InitialStepContainer>
      <Fade delay={200}>
        <WelcomeImage />
      </Fade>
      <Fade delay={400}>
        <h2>{poolInfo?.data.welcomeMessage}</h2>
      </Fade>
      <Fade delay={600}>
        <StyledButton onClick={handleClick}>Start</StyledButton>
      </Fade>
    </InitialStepContainer>
  );
}

const StyledButton = styled(BorderlessButton)`
  background-color: #eee7df;
`;

const InitialStepContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  min-height: 220px;
  svg {
    width: 300px;
    height: 100px;
  }
  h2 {
    color: white;
    font-size: 1.375rem;
    font-weight: 500;
    margin: 1rem 0 2rem;
    text-align: center;
  }
`;
