import { useEffect, useState } from "react";

export default function useLoaded(delay = 0) {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (delay) {
      const timeout = setTimeout(() => setIsLoaded(true), delay);
      return () => clearTimeout(timeout);
    } else {
      setIsLoaded(true);
    }
  }, [delay]);

  return isLoaded;
}
