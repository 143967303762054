import { useEffect, useState } from "react";

export default function useIsAuth() {
  const [isAuth, setIsAuth] = useState(!!localStorage.getItem("authToken"));
  const cb = () => setIsAuth(!!localStorage.getItem("authToken"));

  useEffect(() => {
    window.addEventListener("storage", cb);
    return () => window.removeEventListener("storage", cb);
  }, []);
  return isAuth;
}
