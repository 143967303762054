import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { apiRoute, commonHeaders } from "utils/api";

export default function useCoachLabs() {
  const { data, isFetching: isLabsLoading } = useQuery({
    queryKey: ["useLabs"],
    queryFn: () =>
      axios.get<any>(`${apiRoute}/game/by-coach`, {
        params: { sort: "name" },
        ...commonHeaders(),
      }),
  });

  const labs = data?.data?.data;

  return { labs, isLabsLoading };
}
