import { ComponentProps } from "react";
import styled from "styled-components";

import useLoaded from "../../hooks/utils/useLoaded";

export interface FadeProps extends ComponentProps<typeof Wrapper> {
  delay?: number;
  visible?: boolean;
}

export default function Fade({ delay = 0, visible = true, ...props }: FadeProps) {
  const isLoaded = useLoaded(delay);

  return <Wrapper visible={visible && isLoaded} {...props} />;
}

const Wrapper = styled.div<{ visible: boolean }>`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? "auto" : "none")};
  transition: all 0.40s ease-out;
`;
