import Button, { BorderlessButton } from "components/form/Button";
import ButtonGroup from "components/form/ButtonGroup";
import useKeyAction from "hooks/utils/useKeyAction";
import { useCallback } from "react";
import styled from "styled-components";

import { ReactComponent as ContactImage } from "../components/ContactImage.svg";
import { useOnboardingContext } from "../components/OnboardingUserFlow";

export default function ContactStep() {
  const { setMainStep } = useOnboardingContext() || {};

  const handleClick = useCallback(() => {
    download("https://dh.live/save-contact");
    setMainStep?.("NameAndTimezoneStep");
  }, [setMainStep]);

  // effect that will attach enter on window to go to next step
  useKeyAction({ key: "Enter", action: handleClick });

  return (
    <Container>
      <div />
      <div>
        <ContactImage />
        <h2>Save Dailyhuman's contact card to your phone</h2>
        <div>So you never miss a prompt</div>
      </div>
      <ButtonGroup>
        <BorderlessButton onClick={() => setMainStep?.("NameAndTimezoneStep")}>
          Skip
        </BorderlessButton>
        <Button onClick={handleClick}>Save Contact</Button>
        {/* <BorderlessButton onClick={() => setMainStep?.("PhoneStep")}>
          Back
        </BorderlessButton> */}
      </ButtonGroup>
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  text-align: center;
  h2,
  svg {
    font-size: 1.375rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
`;

function download(url: string) {
  const a = document.createElement("a");
  a.href = url;
  a.download = url.split("/").pop() || "";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
