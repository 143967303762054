import { ComponentProps } from "react";

export default function RecordIcon({ style, ...props }: ComponentProps<"svg">) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      style={{ display: "block" }}
      {...props}
    >
      <circle cx="18" cy="18" r="18" fill="#664DF3" />
      <path
        d="M22 12C22 9.79086 20.2091 8 18 8C15.7909 8 14 9.79086 14 12V18C14 20.2091 15.7909 22 18 22C20.2091 22 22 20.2091 22 18V12Z"
        fill="#F9F7F4"
      />
      <path
        d="M12 17V18C12 21.3137 14.6863 24 18 24V24C21.3137 24 24 21.3137 24 18V17"
        stroke="#F9F7F4"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M18 25V27" stroke="#F9F7F4" strokeWidth="2" strokeLinecap="round" />
      <path d="M21 27L15 27" stroke="#F9F7F4" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}
