import Fade from "components/animations/Fade";
import { useBackground } from "components/base/layout/Background";
import Heading2 from "components/base/text/Heading2";
import { Footer } from "components/Footer";
import Button from "components/form/Button";
import { useNavigateWithFade } from "components/PageRouter/PageRouter";
import useGames from "hooks/useGames";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { formatPrice } from "../../utils/number";

export const ProfileGame = () => {
  useBackground("#f9f7f4");
  const { games } = useGames();
  const { gameid } = useParams<{ gameid: string }>();
  const game = games?.find(game => game.id === gameid);
  const navigate = useNavigateWithFade();

  return (
    <PageWrapper delay={300}>
      <Box>
        <Heading2>{game?.name}</Heading2>
        <Separator />
        <p>
          {game?.price ? (
            <>
              <Bold>${formatPrice(game?.price)}</Bold> per month
            </>
          ) : (
            <Bold>Free</Bold>
          )}
        </p>

        <br />
        <Button onClick={() => navigate(`/profile/game/${game?.id}/unsubscribe`)}>
          Cancel Subscription
        </Button>
      </Box>
      <Footer />
    </PageWrapper>
  );
};

const PageWrapper = styled(Fade)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  min-height: 100dvh;
`;

const Box = styled.div`
  background: #eee7df;
  border-radius: 1rem;
  color: #241f18;
  padding: 1rem;
`;

const Separator = styled.div`
  background: #ab997e;
  height: 1px;
  margin: 1rem 0;
`;

const Bold = styled.span`
  font-weight: bold;
`;
