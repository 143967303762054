import geometry from "./geometry";
import shaders from "./shaders";

type Options = {
  numMetaballs: number;
  minRadius: number;
  maxRadius: number;
  speed: number;
  color: string;
  backgroundColor: string;
};

type Metaball = {
  x: number;
  y: number;
  vx: number;
  vy: number;
  r: number;
};

type CreateMetaballsOptions = {
  minRadius: number;
  maxRadius: number;
  numMetaballs: number;
};

export default function init(canvas: HTMLCanvasElement) {
  const num = window.innerWidth > 768 ? 18 : 12;
  let options = {
    numMetaballs: num,
    minRadius: 2,
    maxRadius: 26,
    speed: 2,
    color: "#525252",
    backgroundColor: "#000000",
  };

  const update = (newOptions: Partial<Options>): void => {
    options = { ...options, ...newOptions };
  };

  const simulateMovement = ({ metaballs }: { metaballs: Metaball[] }) => {
    const speed = Math.max(Math.min(options.speed, 150), -150);
    metaballs.forEach((mb, i) => {
      mb.x = (mb.x + mb.vx * speed + 100) % 100;
      mb.y = (mb.y + mb.vy * speed + 100) % 100;
    });
  };

  /**
   * Shaders setup
   */
  const gl = canvas.getContext("webgl");
  if (!gl) throw new Error("Failed to get WebGL context");
  const { vertexShader, fragmentShader } = shaders({ gl, options });

  const program = gl.createProgram();
  if (!program) throw new Error("Failed to create program");
  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);
  gl.useProgram(program);

  /**
   * Canvas & Metaballs setup
   */
  geometry({ gl, program });

  // resize handler
  const resize = () => {
    const realToCSSPixels = window.devicePixelRatio;
    // alert(gl.canvas.clientWidth)
    // const width = Math.floor(gl.canvas.clientWidth * realToCSSPixels);
    // const height = Math.floor(gl.canvas.clientHeight * realToCSSPixels);
    const width = Math.floor(window.innerWidth * realToCSSPixels);
    const height = Math.floor(window.innerHeight * realToCSSPixels);

    // Check if the canvas is not the same size.
    if (gl.canvas.width !== width || gl.canvas.height !== height) {
      // Make the canvas the same size
      gl.canvas.width = width;
      gl.canvas.height = height;
      gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
    }
  };
  resize();

  const metaballs = createMetaballs(options);
  const metaballsHandle = getMetaballsHandle({ gl, program });

  // get windowSize uniform
  const windowSizeHandle = gl.getUniformLocation(program, "windowSize");

  /**
   * Simulation step, data transfer, and drawing
   */
  let run = true;
  const step = function () {
    const canvasWidth = gl.canvas.width;
    const canvasHeight = gl.canvas.height;
    // Update positions and speeds
    simulateMovement({ metaballs });

    // To send the data to the GPU, we first need to flatten it
    const dataToSendToGPU = new Float32Array(
      metaballs.flatMap(mb => [mb.x, mb.y, mb.r]),
    );

    gl.uniform3fv(metaballsHandle, dataToSendToGPU);
    gl.uniform2fv(windowSizeHandle, new Float32Array([canvasWidth, canvasHeight]));
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);

    if (run) window.requestAnimationFrame(step);
  };

  step();
  window.addEventListener("resize", resize);

  const destroy = () => {
    run = false;
    window.removeEventListener("resize", resize);
  };

  return { destroy, update };
}

const createMetaballs = ({
  minRadius,
  maxRadius,
  numMetaballs,
}: CreateMetaballsOptions): Metaball[] => {
  return Array.from({ length: numMetaballs }, () => {
    const radius = minRadius + Math.random() * (maxRadius - minRadius);
    return {
      x: Math.random() * (100 - radius / 100) + radius / 200,
      y: Math.random() * (100 - radius / 100) + radius / 200,
      vx: ((Math.random() - 0.5) * 5) / 2000,
      vy: ((Math.random() - 0.5) * 2) / 100,
      r: radius,
    };
  });
};

const getMetaballsHandle = ({
  gl,
  program,
}: {
  gl: WebGLRenderingContext;
  program: WebGLProgram;
}) => gl.getUniformLocation(program, "metaballs");

export { createMetaballs, getMetaballsHandle };
