import { ComponentProps } from "react";
import styled from "styled-components";

export default function Label(props: ComponentProps<typeof StyledLabel>) {
  return <StyledLabel {...props} />;
}

const StyledLabel = styled.label`
  color: #241f18;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0.375rem;
  padding-left: 1rem;
`;
