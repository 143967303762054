export function color2vector(color: string) {
  const hexChannels = [
    color.slice(1, 3),
    color.slice(3, 5),
    color.slice(5, 7),
    color.slice(7, 9),
  ];
  const unitChannels = hexChannels.map(hex => parseInt(hex, 16) / 255);
  unitChannels[3] = Number.isFinite(unitChannels[3]) ? unitChannels[3] : 1.0;

  return unitChannels;
}