import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { apiRoute, commonHeaders, queryClient } from "../utils/api";

export default function useGameUnsubscribe({ gameid }: { gameid: string }) {
  const navigate = useNavigate();
  const { mutate: gameUnsubscribe, isPending: isGameUnsubscribeLoading } = useMutation({
    mutationFn: () =>
      axios.post(`${apiRoute}/game/${gameid}/unsubscribe`, {}, commonHeaders()),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getGames"] });
      navigate("/profile");
    },
  });
  return {
    gameUnsubscribe,
    isGameUnsubscribeLoading,
  };
}
