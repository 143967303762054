import { BorderlessButton } from "components/form/Button";
import styled from "styled-components";

import { ReactComponent as CheckImage } from "../components/JoinImage.svg";
import { useOnboardingContext } from "../components/OnboardingUserFlow";
import { useRegisterFormContext } from "../components/RegisterForm";

export const CheckEmailStep = () => {
  const { values } = useRegisterFormContext();
  const { setMainStep } = useOnboardingContext() || {};

  return (
    <Container>
      <div />
      <div>
        <CheckImage />
        <h2>Check your {values.email ? "email" : "phone"}</h2>
        <div>We sent</div>
        <span>
          <b>{values.email || `+${values.phone}`}</b>
        </span>
        <div>a login link</div>
      </div>
      <BorderlessButton
        onClick={() => setMainStep?.(values.email ? "InputEmailStep" : "PhoneStep")}
      >
        Back
      </BorderlessButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  text-align: center;
  h2 {
    font-size: 1.375rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  svg {
    margin-bottom: 1rem;
  }
  b {
    font-weight: 600;
  }
  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
