import useLoaded from "hooks/utils/useLoaded";
import styled from "styled-components";

export default function Progress({ value }: { value: number }) {
  const loaded = useLoaded(100);

  return (
    <Container>
      <Label>{value}%</Label>
      <Bar value={loaded ? value : 0} max={100} />
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.span`
  color: #664df3;
  font-weight: 600;
  text-align: right;
`;

const Bar = styled.progress`
  background-color: transparent;
  height: 0.325rem;
  &::-webkit-progress-bar {
    background-color: #c7bef8;
    border-radius: 0.5rem;
  }
  &::-webkit-progress-value {
    background-color: #664df3;
    border-radius: 0.5rem;
    transition: all 0.4s ease-out;
  }
  width: 100%;
`;
