import styled from "styled-components";

const Table = ({ children }: { children: React.ReactNode }) => {
  return <RealTable>{children}</RealTable>;
};

const RealTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const THead = styled.thead`
  color: #f9f7f4;
`;

const TBody = styled.tbody`
  > tr:first-child td:nth-child(1) {
    border-top-left-radius: 1rem;
  }
  > tr:first-child td:last-child {
    border-top-right-radius: 1rem;
  }
  > tr:last-child td:nth-child(1) {
    border-bottom-left-radius: 1rem;
  }
  > tr:last-child td:last-child {
    border-bottom-right-radius: 1rem;
  }
  tr:nth-child(even) td {
    background: #ffffff;
  }
  tr:nth-child(odd) td {
    background: #f0ece6;
  }
`;

const TR = styled.tr``;

const TH = styled.th`
  font-size: 0.875rem;
  padding: 0.25rem 1rem;
  text-align: left;
  vertical-align: bottom;
`;

const TD = styled.td`
  background-color: #eee7dfcc;
  font-size: 0.875rem;
  padding: 1rem;
  text-align: left;
  vertical-align: middle;
`;

export default Table;

export { TBody, TD, TH, THead, TR };
