import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

type SwiperItemProps = {
  children?: React.ReactNode;
  containerRef?: React.RefObject<HTMLDivElement>;
  active?: boolean;
};

export default function SwiperItem({
  children,
  containerRef,
  active,
  ...props
}: SwiperItemProps) {
  const itemRef = useRef<HTMLDivElement>(null);
  const [opacity, setOpacity] = useState(0);
  // when containerRef scroll calculate and console position oh this item relative to viewpoert center
  useEffect(() => {
    if (!active) return;
    const container = containerRef?.current;
    const handleScroll = () => {
      const item = itemRef?.current;
      if (!container || !item) return;
      const containerRect = container.getBoundingClientRect();
      const itemRect = item.getBoundingClientRect();
      if (containerRect.width < 720) return;
      const containerCenter = containerRect.width / 2 + containerRect.left;
      const itemCenter = itemRect.width / 2 + itemRect.left;
      const position = itemCenter - containerCenter;
      const opacity = 1 - Math.abs(position) / 300;
      setOpacity(opacity);
    };
    handleScroll();
    // console log current time

    container?.addEventListener("scroll", handleScroll);
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [active, containerRef]);

  return (
    <SwiperItemContainer {...props} ref={itemRef} style={{ opacity }}>
      {children}
    </SwiperItemContainer>
  );
}

const SwiperItemContainer = styled.div`
  max-width: calc(100vw - 6rem);
  position: relative;
  scroll-snap-align: center;
  & > img {
    max-width: -webkit-fill-available;
  }
`;
