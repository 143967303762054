import styled from "styled-components";

type FooterPropsType = {
  styles?: any;
};

export const Footer = (props: FooterPropsType) => {
  return (
    <FooterWrapper extraStyles={props.styles}>
      <LinksContainer>
        <Link href="https://dailyhuman.com/privacy" target="_blank">
          Privacy
        </Link>
        <Link href="https://dailyhuman.com/terms" target="_blank">
          Terms
        </Link>
      </LinksContainer>
    </FooterWrapper>
  );
};

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: flex-end;
  padding-right: 20px;
  width: 100%;
`;

const Link = styled.a`
  color: #363636;
  font-style: normal;
  :hover {
    color: #363636;
    cursor: pointer;
  }
`;

const FooterWrapper = styled.div<{ extraStyles?: any }>`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  ${props => props.extraStyles}
`;
