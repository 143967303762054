import { IListJourneyV3Res } from "@dailyhuman/web-core/dist/api/game/res";
import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import useScrollAction from "hooks/utils/useScrollAction";
import { apiRoute, commonHeaders } from "utils/api";

type UserJourneyProps = {
  userid?: string;
  page?: number;
};

function useAnalyticsGroupUser({ userid }: UserJourneyProps) {
  const { data, isFetching, fetchNextPage } = useInfiniteQuery({
    queryKey: ["getAnalticsUserJourney", userid],
    queryFn: ({ pageParam = 1 }) =>
      axios.get<IListJourneyV3Res>(
        `${apiRoute?.slice(0, -3)}/v1/recognition/analytics/journey/user/${userid}`,
        {
          params: { page: pageParam, limit: 20 },
          ...commonHeaders(),
        },
      ),
    getNextPageParam: lastPage => {
      return lastPage.data.pagination.total / 20 > lastPage.data.pagination.page
        ? lastPage.data.pagination.page + 1
        : undefined;
    },
    initialPageParam: 1,
  });

  useScrollAction({ action: fetchNextPage });

  const journey = data?.pages.map(page => page.data.data).flat();

  return { journey, isJourneyLoading: isFetching, fetchNextJourneyPage: fetchNextPage };
}

export default useAnalyticsGroupUser;
