import { IGetUserAnalyticsResV2 } from "@dailyhuman/web-core/dist/api/game/res";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { apiRoute, commonHeaders } from "utils/api";

export default function useAnalyticsLab(labId?: string) {
  const { data, isFetching: isAnalyticsLabLoading } = useQuery({
    queryKey: ["useAnalyticsLab", labId],
    queryFn: () =>
      axios.get<IGetUserAnalyticsResV2>(
        `${apiRoute}/recognition/analytics/game/${labId}`,
        {
          ...commonHeaders(),
        },
      ),
    enabled: !!labId, // Ensures the query only runs when labId is not null or undefined
  });

  const analyticsLab = data?.data?.result;

  return { analyticsLab, isAnalyticsLabLoading };
}
