import * as yup from "yup";

export const emailValidation = yup.string().trim().email("Incorrect email").max(64);

export const phoneValidation = yup
  .string()
  .trim()
  .matches(/^\+?\d+$/, "Only digits are accepted")
  .min(10, "Phone number too short")
  .max(15, "Phone number too long")
  .optional();
