import { ReactComponent as Labs } from "components/base/Menu/icons/Labs.svg";
import Epoch from "components/base/text/Epoch";
import Heading2 from "components/base/text/Heading2";
import Button from "components/form/Button";
import { Buckets } from "hooks/useGetUserGames";
import styled from "styled-components";

import { SectionSmall } from "./JourneySection";

export default function JourneyHeader({ cycle }: { cycle: Buckets }) {
  return (
    <SectionSmall>
      <Heading2>{cycle.game.name}</Heading2>
      <a href="/labs">
        <Button icon={Labs}>Answer</Button>
      </a>
      <SectionFooter>
        Expires <Epoch date={cycle.forceCompleteAt} />
      </SectionFooter>
    </SectionSmall>
  );
}

const SectionFooter = styled.div`
  color: #ab997e;
`;
