import { useFormikContext } from "formik";
import { ComponentProps, useEffect, useState } from "react";
import styled from "styled-components";

import Loading from "../Loading";

export interface ButtonProps extends ComponentProps<typeof StyledButton> {
  loading?: boolean;
  icon?: () => JSX.Element;
}

export default function Button({
  loading,
  disabled,
  onClick,
  type,
  ...props
}: ButtonProps) {
  const Icon = props.icon;
  const [isLoadingShown, setIsLoadingShown] = useState(false);

  useEffect(() => {
    if (loading) {
      setIsLoadingShown(true);
    } else {
      const timeout = setTimeout(() => {
        setIsLoadingShown(false);
      }, 600);
      return () => clearTimeout(timeout);
    }
  }, [loading]);

  return (
    <StyledButton
      disabled={disabled || isLoadingShown}
      onClick={onClick}
      isLoadingShown={isLoadingShown}
      type={type || "button"}
      {...props}
    >
      {Icon && (
        <IconContainer>
          <Icon />
        </IconContainer>
      )}
      {props.children}
      <LoadingWrapper>{isLoadingShown && <Loading loop={loading} />}</LoadingWrapper>
    </StyledButton>
  );
}

const StyledButton = styled.button<{ isLoadingShown?: boolean }>`
  background: #664df3;
  border: none;
  border-radius: 99px;
  color: ${({ isLoadingShown }) => (isLoadingShown ? "transparent" : "#f9f7f4;")};
  display: block;
  font-family: "Matter", sans-serif;
  font-size: inherit;
  padding: 0.75em 1.5em;
  position: relative;
  transition: all 0.3s ease-in-out;
  &:disabled {
    opacity: 0.5;
  }
  &:not(:disabled):not(:active):hover {
    cursor: pointer;
    transform: scale(1.03);
  }
  &:active {
    transform: scale(0.92);
  }
`;

const IconContainer = styled.span`
  display: inline-block;
  width: 1.125em;
  & > svg,
  & > img {
    border-radius: 50%;
    height: 2em;
    left: 0.25em;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 2em;
  }
`;

export const LoadingWrapper = styled.div`
  height: 100%;
  position: absolute;
  right: 47%;
  top: 50%;
  transform: translateY(-50%) translateX(50%);
  width: 50%;
`;

export const BorderlessButton = styled(Button)`
  background: transparent;
  border: 1px solid transparent;
  color: #664df3;
  &:hover {
    transform: none;
  }
`;

export const BorderlessButtonLink = styled('a')`
  background: transparent;
  border: 1px solid transparent;
  color: #664df3;
  padding: 12px 24px;
  &:hover {
    transform: none;
  }
`;

export function SubmitButton<T>({ children, disabled, ...props }: ButtonProps) {
  const context = useFormikContext<T>();
  const { isValid, dirty } = context || {};
  disabled =
    disabled || (context && !props.onClick && (!isValid || !dirty || props.loading));

  return (
    <Button type="submit" disabled={disabled} {...props}>
      {children}
    </Button>
  );
}
