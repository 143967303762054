import { SubmitButton } from "components/form/Button";
import ButtonGroup from "components/form/ButtonGroup";
import EmailInput from "components/form/EmailInput";
import Field from "components/form/Field";
import { Form, Formik } from "formik";
import { ComponentProps } from "react";
import styled from "styled-components";
import { emailValidation } from "utils/validation";
import * as yup from "yup";

const validationSchema = yup.object({
  email: emailValidation,
});

export interface MagicLinkFormProps extends ComponentProps<typeof Formik> {
  loading?: boolean;
}

export default function MagicLinkForm({ loading, ...props }: MagicLinkFormProps) {
  return (
    <Formik {...props} validationSchema={validationSchema}>
      <Form>
        <FieldContainer>
          <Field as={EmailInput} name="email" />
        </FieldContainer>
        <ButtonGroup>
          <SubmitButton loading={loading}>Login Link</SubmitButton>
        </ButtonGroup>
      </Form>
    </Formik>
  );
}

const FieldContainer = styled.div`
  margin-bottom: 0.5rem;
`;
