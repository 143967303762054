// React component using styled components on a menu button, it is a circle fixed at top right

import styled from "styled-components";

import { ReactComponent as Close } from "./icons/Close.svg";
import { ReactComponent as Open } from "./icons/Open.svg";

type MenuButtonProps = {
  onClick: () => void;
  open: boolean;
};

export default function MenuButton({ onClick, open }: MenuButtonProps) {
  return (
    <Button onClick={onClick} className="MenuButton">
      {open ? <Close /> : <Open />}
    </Button>
  );
}

const Button = styled.button`
  align-items: center;
  background: #f9f7f4;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.22);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  padding: 0;
  position: fixed;
  right: 1rem;
  top: 1rem;
  transition: all 0.1s linear;
  width: 50px;
  z-index: 1001;
  &:active {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    transform: scale(0.92);
  }
`;
