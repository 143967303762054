import { useRive, useStateMachineInput } from "@rive-app/react-canvas";
import { useFormikContext } from "formik";
import { ComponentProps } from "react";
import styled from "styled-components";

import ToggleRiv from "./Toggle.riv";

const stateMachine = "State Machine";

interface ToggleProps<T> extends ComponentProps<typeof Checkbox> {
  name?: keyof T;
}

export default function Toggle<T>({ ...props }: ToggleProps<T>) {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<T>();
  const { rive, RiveComponent } = useRive({
    src: ToggleRiv,
    stateMachines: stateMachine,
    autoplay: true,
    shouldDisableRiveListeners: true,
  });

  const name = props.name;
  const value = name && values[name];

  const handleClick = () => {
    if (!name) return;
    setFieldValue(name.toString(), !value);
    setTimeout(() => setFieldTouched(name.toString(), true));
  };

  const valueInput = useStateMachineInput(rive, stateMachine, "value");
  const disabledInput = useStateMachineInput(rive, stateMachine, "disabled");

  if (valueInput && disabledInput) {
    valueInput.value = !!value;
    setTimeout(() => {
      disabledInput.value = !!props.disabled;
    }, 50);
  }

  return (
    <Container disabled={props.disabled}>
      <RiveComponent
        style={{ width: 48, height: 24, cursor: "pointer" }}
        onClick={handleClick}
      />
      <Checkbox {...props} />
    </Container>
  );
}

const Container = styled.div<{ disabled?: boolean }>`
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "all")};
  transition: opacity 0.2s;
`;

const Checkbox = styled.input`
  display: none;
`;
