import Accordion from "components/animations/Accordion";
import Box from "components/base/Box";
import EmptyContent from "components/base/EmptyContent";
import { useBackground } from "components/base/layout/Background";
import Progress from "components/base/Progress";
import Table, { TBody, TD, TH, THead, TR } from "components/base/Table";
import Heading1 from "components/base/text/Heading1";
import Heading3 from "components/base/text/Heading3";
import Small from "components/base/text/Small";
import Button from "components/form/Button";
import DateSelect from "components/form/DateSelect";
import Input from "components/form/Input";
import Select from "components/form/Select/Select";
import dayjs from "dayjs";
import useCoachLabs from "hooks/labs/useCoachLabs";
import useEngagementReport from "hooks/reports/useEngagementReport";
import useEngagementReportExport from "hooks/reports/useEngagementReportExport";
import useEngagementReportSummary from "hooks/reports/useEngagementReportSummary";
import { ComponentProps, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as ReportIcon } from "./AnalyticsGroup/Report.svg";

export default function ReportPage() {
  useBackground("rgb(102,77,243)", true);
  const { gameid } = useParams<{ gameid: string }>();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { engagementReport } = useEngagementReport(gameid, {
    filter: { firstName, lastName, startDate, endDate },
  });
  const { engagementReportExport } = useEngagementReportExport(gameid, {
    filter: { firstName, lastName, startDate, endDate },
  });
  const { engagementReportSummary } = useEngagementReportSummary(gameid);
  const { labs, isLabsLoading } = useCoachLabs();
  const navigate = useNavigate();

  const formatPercentage = (value: number | undefined) =>
    value != null ? `${value.toFixed(0)}%` : "N/A";

  useEffect(() => {
    if (!gameid && labs?.length) {
      navigate(window.location.pathname + "/" + labs[0].id, { replace: true });
    }
  }, [gameid, labs, navigate]);

  if (isLabsLoading) return null;

  return (
    <Page>
      <Toolbar>
        <Select
          value={gameid || ""}
          onChange={(e: any) => navigate(`/report/${e.target.value}`)}
        >
          {labs?.sort().map((lab: any) => (
            <option key={lab.id} value={lab.id}>
              {lab.name} | {lab.group.name}
            </option>
          ))}
        </Select>
        <Button
          style={{ background: "white", color: "#241F18" }}
          icon={ReportIcon}
          onClick={() => navigate("/analytics/group")}
        >
          Group Dashboard
        </Button>
      </Toolbar>
      <Header>
        <span>{labs?.find((lab: any) => lab.id === gameid)?.name}</span>
        <Heading1>Activity Report</Heading1>
      </Header>

      <Summary>
        <Box>
          <div>
            <Heading3>Total engagement</Heading3>
            <Small>Percentage based on all prompts</Small>
          </div>
          <Heading1>
            {formatPercentage(engagementReportSummary?.avgCompletion)}
          </Heading1>
        </Box>
        <Box>
          <div>
            <Heading3>Recently engaged</Heading3>
            <Small>Percentage of users completing 2 of last 3 prompts</Small>
          </div>
          <Heading1>
            {formatPercentage(engagementReportSummary?.avgDoneTwoOfThree)}
          </Heading1>
        </Box>
      </Summary>

      <WidthLimiter>
        <FiltersBox>
          <FiltersHeader>
            <Heading3>&nbsp; Filters</Heading3>
            <Button onClick={engagementReportExport}>Export CSV</Button>
          </FiltersHeader>
          <Filters>
            <Input
              placeholder="First Name"
              onChange={(e: any) => setFirstName(e.target.value)}
            />
            <Input
              placeholder="Last Name"
              onChange={(e: any) => setLastName(e.target.value)}
            />
            <DateSelect
              placeholder="Start Date"
              onChange={(e: any) => setStartDate(e.target.value)}
              value={startDate}
            />
            <DateSelect
              placeholder="End Date"
              onChange={(e: any) => setEndDate(e.target.value)}
              value={endDate}
            />
          </Filters>
        </FiltersBox>
      </WidthLimiter>
      {engagementReport?.length === 0 && <EmptyContent />}
      {engagementReport?.length && (
        <TableLimiter>
          <Table>
            <THead>
              <TR>
                <TH>First Name</TH>
                <TH>Last Name</TH>
                <TH>Engagement</TH>
                <TH>Percent Completed</TH>
                <TH>Completed 2 of last 3 prompts</TH>
              </TR>
            </THead>
            <TBody>
              {engagementReport?.map((item: any, i: number) => (
                <TR key={item.user.id}>
                  <TD>{item.user.firstName}</TD>
                  <TD>{item.user.lastName}</TD>
                  <TD>
                    <LabExpander>
                      {item.engagement
                        .filter(
                          (engagement: any) =>
                            !["PREVIEW", "PENDING", "TERMINATED", "CLOSED"].includes(
                              engagement.status,
                            ),
                        )
                        .map((engagement: any, i: number) => (
                          <div key={i} style={{ display: "flex", gap: "0.25rem" }}>
                            <div title={engagement.status}>
                              {engagement.status === "COMPLETED" ? (
                                <YesIcon />
                              ) : (
                                <NoIcon />
                              )}
                            </div>
                            <div>
                              <div
                                style={{
                                  whiteSpace: "nowrap",
                                  fontWeight: "bold",
                                  maxWidth: "200px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                title={engagement.prompt.name}
                              >
                                {engagement.prompt.name}
                              </div>
                              <div>
                                {dayjs(engagement.start).format("MMM DD YYYY HH:mm")}
                              </div>
                            </div>
                          </div>
                        ))}
                    </LabExpander>
                  </TD>
                  <TD>
                    <Progress value={item.completion} />
                  </TD>
                  <TD>
                    <div
                      style={{
                        display: "flex",
                        gap: "0.25rem",
                        justifyContent: "center",
                      }}
                    >
                      {item.doneTwoOfThree ? (
                        <>
                          <YesIcon /> Yes
                        </>
                      ) : (
                        <>
                          <NoIcon /> No
                        </>
                      )}
                    </div>
                  </TD>
                </TR>
              ))}
            </TBody>
          </Table>
        </TableLimiter>
      )}
    </Page>
  );
}

interface LabExpanderProps extends ComponentProps<typeof Accordion> {
  children: Array<React.ReactNode>;
}

const LabExpander = (props: LabExpanderProps) => {
  const [expanded, setExpanded] = useState(false);
  if (typeof props.children !== "object") return null;
  if (!props.children || Array.isArray(props.children) === false) return null;
  if (props.children?.length < 4) return <>{props.children}</>;

  const children = expanded ? props.children : props.children.slice(0, 3);
  const label = expanded ? (
    <>Show less</>
  ) : (
    <>Show more ({props.children?.length - 3})</>
  );
  return (
    <Accordion>
      {children}
      {props.children?.length > 3 && (
        <ShowMore onClick={() => setExpanded(!expanded)}>{label}</ShowMore>
      )}
    </Accordion>
  );
};

const ShowMore = styled.div`
  color: #664df3;
  cursor: pointer;
  text-decoration: underline;
`;

const Page = styled.div`
  padding-top: 4rem;
`;

const WidthLimiter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
  max-width: 960px;
  padding: 1rem;
`;

const Toolbar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  left: 1rem;
  max-width: calc(100vw - 7rem);
  position: absolute;
  top: 1rem;
  z-index: 1;
  @media (min-width: 600px) {
    flex-direction: row;
  }
`;

const Header = styled.div`
  align-items: center;
  color: #f9f7f4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0;
`;

const Summary = styled.div`
  display: flex;
  gap: 1rem;
  margin: 0 auto;
  max-width: 720px;
  padding: 1rem;
  & > * {
    align-items: center;
    background: #5339a4;
    color: white;
    display: flex;
    flex: 1;
    gap: 1rem;
  }
`;

const FiltersBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-x: hidden;
`;

const FiltersHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const Filters = styled.div`
  display: flex;
  gap: 1rem;
  & > * {
    flex: 1;
  }
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const TableLimiter = styled(WidthLimiter)`
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const YesIcon = () => (
  <svg width="24" height="26" viewBox="-4 -4 24 24" fill="none">
    <rect width="16" height="16" rx="8" fill="#63BCA5" />
    <path d="M3 8L6.5 11.5L13 5" stroke="#F9F7F4" strokeWidth="2" />
  </svg>
);

const NoIcon = () => (
  <svg width="24" height="26" viewBox="-4 -4 24 24" fill="none">
    <rect width="16" height="16" rx="8" fill="#E45D57" />
    <path d="M4 12L12 4" stroke="#F9F7F4" strokeWidth="2" />
    <path d="M12 12L4 4" stroke="#F9F7F4" strokeWidth="2" />
  </svg>
);
