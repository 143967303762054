import { Axis, Orientation } from "@visx/axis";
import { GridColumns } from "@visx/grid";
import { scaleLinear } from "@visx/scale";
import { LinePath } from "@visx/shape";
import { Text } from "@visx/text";
import dayjs from "dayjs";
import useWidth from "hooks/utils/useWidth";
import { useEffect, useRef } from "react";
import { TCapacityHistoryItem } from "types/analytics";

import Header from "./Header";

const defaultData: TCapacityHistoryItem[] = [
  {
    date: new Date(),
    cycleId: "1",
    value: 39,
    max: 60,
    min: 24,
    avg: 34,
  },
  {
    date: new Date(),
    cycleId: "2",
    value: 30,
    max: 60,
    min: 24,
    avg: 28,
  },
  {
    date: new Date(),
    cycleId: "3",
    value: 68,
    max: 68,
    min: 24,
    avg: 34,
  },
  {
    date: new Date(),
    cycleId: "4",
    value: 28,
    max: 68,
    min: 24,
    avg: 34,
  },
  {
    date: new Date(),
    cycleId: "5",
    value: 20,
    max: 68,
    min: 20,
    avg: 38,
  },
];

type LinearChartProps = {
  data?: TCapacityHistoryItem[];
  width?: number;
  height?: number;
  onSelected?: (index: number) => void;
  selected?: number;
};

function getChartRange(data: TCapacityHistoryItem[]) {
  const filteredData = data.filter(item => item.max !== null && item.min !== null);
  if (filteredData.length === 0) return [-15, 15];

  const maxValues = filteredData.map(item => item.max || 0);
  const minValues = filteredData.map(item => item.min || 0);
  const max = Math.max(...maxValues);
  const min = Math.min(...minValues);

  return [min - 15, max + 15];
}

export default function LinearChart({
  onSelected,
  data = defaultData,
  height = 180,
  selected = data.length - 1,
}: LinearChartProps) {
  const selectedData = data[selected];

  const ref = useRef<HTMLDivElement>(null);
  const width = useWidth(ref);

  const yScale = scaleLinear({
    domain: getChartRange(data),
    range: [height - 32, 0],
  });

  const xScale = scaleLinear({
    domain: [0, Object.keys(data).length - 1],
    range: [0, width - 32],
  });

  useEffect(() => {
    if (onSelected) {
      onSelected(selected);
    }
  }, [selected, onSelected]);

  return (
    <div ref={ref} className="LinearChart">
      <Header selectedData={selectedData} />
      <svg width={width} height={height}>
        <g transform="translate(16)">
          <GridColumns
            key={`gridcolumns-1`}
            scale={xScale}
            stroke={"#eee7df"}
            height={height - 30}
            numTicks={data.length - 1}
            onClick={() => alert("clicked")}
          />
          <LinePath
            data={data
              .slice(data.findIndex(d => d.avg !== null))
              .map?.((d, i) => [
                xScale(i + data.findIndex(d => d.avg !== null)),
                yScale(d.avg || 0),
              ])}
            stroke={"#664DF3"}
            strokeWidth={2}
            strokeDasharray={"4,4"}
          />
          {/* <LinePath
            data={data
              .slice(data.findIndex(d => d.max !== null))
              .map?.((d, i) => [
                xScale(i + data.findIndex(d => d.max !== null)),
                yScale(d.max || 0),
              ])}
            stroke={"#BCD643"}
            strokeWidth={4}
          />
          <LinePath
            data={data
              .slice(data.findIndex(d => d.min !== null))
              .map?.((d, i) => [
                xScale(i + data.findIndex(d => d.min !== null)),
                yScale(d.min || 0),
              ])}
            stroke={"#AB997E"}
            strokeWidth={2}
          /> */}
          {data.slice(data.findIndex(d => d.avg !== null)).map?.((item, i) => {
            const hasValue = item.value !== null;
            return item.insight ? (
              <circle
                key={i}
                cx={xScale(i + data.findIndex(d => d.avg !== null))}
                cy={yScale((hasValue ? item.value : item.avg) || 0)}
                r={4}
                fill={hasValue ? "#664DF3" : "white"}
                stroke="#664DF3"
                strokeWidth={2}
              />
            ) : (
              <ChartPointSkipped
                key={i}
                x={xScale(i + data.findIndex(d => d.avg !== null)) - 5}
                y={yScale((hasValue ? item.value : item.avg) || 0) - 5}
              />
            );
          })}
          {/* <g
            transform={`translate(${width - 36}, ${yScale(
              data[data.length - 1]?.max || 0,
            )})`}
          >
            <circle r={15} fill={"#BCD643"} />
            <Text y={-3} fontSize={10} textAnchor="middle" fill={"black"}>
              roof
            </Text>
            <Text y={10} fontSize={14} textAnchor="middle" fill={"black"}>
              {Math.floor(data[data.length - 1]?.max || 0)}
            </Text>
          </g>
          <g
            transform={`translate(${width - 36}, ${yScale(
              data[data.length - 1]?.min || 0,
            )})`}
          >
            <circle r={15} fill={"#AB997E"} />
            <Text y={-3} fontSize={10} textAnchor="middle" fill={"white"}>
              floor
            </Text>
            <Text y={10} fontSize={14} textAnchor="middle" fill={"white"}>
              {data[data.length - 1]?.min || 0}
            </Text>
          </g> */}
          <Axis
            key={`axis-1`}
            orientation={Orientation.bottom}
            top={height - 34}
            scale={xScale}
            stroke={"transparent"}
            tickStroke={"transparent"}
            tickValues={Array.from(Array(data.length).keys())}
            tickFormat={d => d.toString()}
            tickComponent={props => {
              const { x, y, formattedValue } = props;
              const index = parseInt(formattedValue || "0");
              const isSelected = selected === index;
              return (
                <g
                  transform={`translate(${x}, ${y})`}
                  onClick={() => onSelected?.(index)}
                  style={{ cursor: "pointer" }}
                >
                  <rect
                    x={-14}
                    y={-12}
                    width={28}
                    height={28}
                    rx={5}
                    ry={5}
                    fill={isSelected ? "#664DF3" : "#eee7df"}
                  />
                  <g fill={isSelected ? "white" : "black"}>
                    <Text fontSize={11} textAnchor="middle">
                      {dayjs(data[index].date).format("MMM")}
                    </Text>
                    <Text y={11} fontSize={11} textAnchor="middle">
                      {dayjs(data[index].date).format("D")}
                    </Text>
                  </g>
                </g>
              );
            }}
          />
          <LinePath
            data={[
              [xScale(selected), 0],
              [xScale(selected), height - 30],
            ]}
            stroke={"#664DF3"}
            strokeWidth={1}
          />
        </g>
      </svg>
    </div>
  );
}

const ChartPointSkipped = (props: any) => (
  <svg width="10" height="10" viewBox="0 0 8 8" fill="none" {...props}>
    <circle cx="4" cy="4" r="4" fill="#E45D57" />
    <path d="M2 2L6 6M6 2L2 6" stroke="#F9F7F4" strokeLinecap="round" />
  </svg>
);
