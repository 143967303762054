import { BorderlessButton } from "components/form/Button";
import ButtonGroup from "components/form/ButtonGroup";
import CodeInput from "components/form/CodeInput";
import { useVerifyTransport } from "hooks/useVerifyTransport";
import { ComponentProps, useEffect, useState } from "react";
import styled from "styled-components";

interface VerificationProps extends ComponentProps<typeof VerificationContainer> {
  email?: string;
  phone?: string;
  onComplete: (code: string, isWhatsapp: boolean) => void;
}

export default function Verification({
  email,
  phone,
  onComplete,
  ...props
}: VerificationProps) {
  const { sendVerificationRequest } = useVerifyTransport();
  const [resend, resendTimer] = useRateLimitedAction(() => {
    sendVerificationRequest({
      type: phone ? "SMS" : "EMAIL",
      destination: phone || email || "",
    });
  });
  const [resendWhatsapp, resendTimerWhatsapp, resendWhatsappTries] =
    useRateLimitedAction(() => {
      sendVerificationRequest({
        type: "WHATSAPP",
        destination: phone || "",
      });
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(resend, []);

  const buttonLabel =
    resendTimer > 0 ? `Resend code in ${resendTimer}s` : `Resend code`;
  const buttonLabelWhatsapp =
    resendTimerWhatsapp > 0
      ? `Resend WhatsApp in ${resendTimerWhatsapp}s`
      : resendWhatsappTries
        ? "Resend WhatsApp"
        : "Use WhatsApp instead";

  return (
    <VerificationContainer {...props}>
      <div>
        <h2>We sent you a verification code</h2>
        <span>
          Please check{" "}
          <b>
            {phone && "+"}
            {phone || email}
          </b>
        </span>
      </div>
      <CodeInput length={6} onComplete={c => onComplete(c, !!resendWhatsappTries)} />
      <ButtonGroup>
        <BorderlessButton onClick={resend} disabled={resendTimer !== 0}>
          {buttonLabel}
        </BorderlessButton>
        {phone && (
          <BorderlessButton
            onClick={resendWhatsapp}
            disabled={resendTimerWhatsapp !== 0}
          >
            {buttonLabelWhatsapp}
          </BorderlessButton>
        )}
      </ButtonGroup>
    </VerificationContainer>
  );
}

const VerificationContainer = styled.div`
  text-align: center;
  h2 {
    margin-bottom: 2rem;
    font-weight: 500;
    font-size: 1.375rem;
    padding: 0 1rem;
  }
  div > span {
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    margin-bottom: 1rem;
  }
  b {
    font-weight: 600;
  }
`;

const useRateLimitedAction = (action: () => void): [() => void, number, number] => {
  const [timer, setTimer] = useState<number>(0);
  const [tries, setTries] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(prev => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const act = () => {
    if (timer === 0) {
      action();
      setTimer(15 * tries);
      setTries(prev => prev + 1);
    }
  };

  return [act, timer, tries];
};
