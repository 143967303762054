import { IListGameEngagementRes } from "@dailyhuman/web-core/dist/api/game";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { apiRoute, commonHeaders } from "utils/api";

export default function useEngagementReportSummary(gameid?: string) {
  const { data, isFetching: isEngagementReportSummaryLoading } = useQuery({
    queryKey: ["useEngagementReports", gameid],
    queryFn: () =>
      axios.get<IListGameEngagementRes>(`${apiRoute}/engagement/game`, {
        params: {
          page: 1,
          limit: 1,
          id: gameid,
        },
        ...commonHeaders(),
      }),
    enabled: !!gameid,
  });

  return {
    engagementReportSummary: data?.data?.data?.[0],
    isEngagementReportSummaryLoading,
  };
}
