import { MessageTransport, UserProfile } from "@dailyhuman/web-core/dist/api/user";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import ReactGA from "react-ga4";
import { TProfile } from "types/user";
import { apiRoute, commonHeaders } from "utils/api";

import useIsAuth from "./useIsAuth";

export enum NotificationMethod {
  WHATSAPP = "WHATSAPP",
  SMS = "SMS",
  EMAIL = "EMAIL",
}

export type ProfileData = UserProfile;

export default function useProfile() {
  const isAuth = useIsAuth();

  const { data, isFetching: isProfileLoading } = useQuery({
    queryKey: ["getProfile", isAuth],
    queryFn: loadProfile,
    enabled: isAuth,
    refetchOnMount: false,
  });

  const profile = data?.data ? mapResponseToProfile(data.data) : undefined;

  ReactGA.set({ userId: data?.data.id });

  return { profile: data?.data, isProfileLoading, newProfile: profile };
}

function loadProfile() {
  return axios.get<UserProfile>(`${apiRoute}/user/me`, commonHeaders());
}

const { EMAIL, SMS, WHATSAPP } = MessageTransport;

const mapResponseToProfile = (data: UserProfile): TProfile => ({
  email: data.email,
  phone: data.phone || "",
  firstName: data.firstName,
  lastName: data.lastName,
  timezone: data.timezone,
  isAdult: data.isAdult || false,
  notificationReceiveEmail: data.notificationMethod.includes(EMAIL),
  notificationReceiveSMS: data.notificationMethod.includes(SMS),
  notificationReceiveWhatsapp: data.notificationMethod.includes(WHATSAPP),
  parentsEmail: data.parentsEmail || "",
  pronouns: data.pronouns,
});
