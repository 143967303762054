import Small from "components/base/text/Small";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { ComponentProps, useEffect, useState } from "react";
import styled from "styled-components";

import RecordIcon from "./RecordIcon";

dayjs.extend(duration);

export default function RecordIndicator(props: ComponentProps<typeof RecordContainer>) {
  const [elapsed, setElapsed] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsed(elapsed => elapsed + 1);
    }, 999);
    return () => clearInterval(interval);
  }, []);

  return (
    <RecordContainer {...props}>
      <div>
        <RecordIcon width={72} height={82} />
      </div>
      <Small style={{ fontWeight: "bold" }}>
        {dayjs.duration(elapsed * 1000).format("mm:ss")}
      </Small>
    </RecordContainer>
  );
}

const RecordContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem;
  text-align: center;
`;
