import Button from "components/form/Button";
import { useEffect, useMemo, useState } from "react";

declare global {
  interface Window {
    SpeechRecognition: any;
    webkitSpeechRecognition: any;
  }
}

export default function SPT() {
  const [transcript, setTranscript] = useState("");
  const [current, setCurrent] = useState("");
  const [isListening, setIsListening] = useState(false);

  const recognition = useMemo(() => new window.webkitSpeechRecognition(), []);

  useEffect(() => {
    if (isListening) {
      recognition.lang = "en-US"; // Set the language (optional)
      recognition.interimResults = true; // Final results only
      recognition.onresult = (event: any) => {
        const spokenText = event.results[0][0].transcript;
        const isFinal = event.results[0].isFinal;
        setCurrent(spokenText);
        if (isFinal) {
          setTranscript(t => t + " " + spokenText);
          setCurrent("");
        }
      };
      recognition.onerror = (event: any) => {
        console.error("Speech recognition error:", event.error);
      };
      recognition.onend = () => {
        recognition.start();
      };
      recognition.start();
    } else {
      recognition.onend = () => {};
      recognition.stop();
    }
  }, [isListening, recognition]);

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <Button onClick={() => setIsListening(!isListening)}>
        {isListening ? "Listening..." : "Start Listening"}
      </Button>
      <p style={{ marginTop: "20px", fontSize: "18px", fontStyle: "italic" }}>
        {transcript} {current}
      </p>
    </div>
  );
}
