import { captureConsoleIntegration, httpClientIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import version from "../../package.json";

export function sentryInit() {
  const { hostname } = window.location;
  const shortHostname = hostname.replace(/dh-customer-ui-git-|\.com|\.app.*/g, "");

  Sentry.init({
    dsn: "https://d4ca9c381d06cac713b676086da8a7d9@us.sentry.io/4506700119343104",
    environment: shortHostname,
    release: `${version.name}@${version.version}`,
    integrations: [
      httpClientIntegration(),
      captureConsoleIntegration({ levels: ["warn", "error"] }),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/(?:.*\.)?vercel\.app/,
      /^https:\/\/(?:.*\.)?dailyhuman\.com/,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}
