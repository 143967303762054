import { ComponentProps } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import styled from "styled-components";

interface SwitchProps extends ComponentProps<typeof Container> {
  renderKey: string;
}

export default function Switch({ children, renderKey, ...props }: SwitchProps) {
  return (
    <Container {...props}>
      <SwitchTransition>
        <CSSTransition key={renderKey} classNames="fade" timeout={500}>
          <div>{children}</div>
        </CSSTransition>
      </SwitchTransition>
    </Container>
  );
}

const Container = styled.div`
  & > div.fade-enter {
    opacity: 0;
    transition: all 0.3s ease-in 0.1s;
  }
  & > div.fade-enter-active,
  & > div.fade-enter-done {
    opacity: 1;
  }
  & > div.fade-exit {
    transition: all 0.4s ease-out;
  }
  & > div.fade-exit-active,
  & > div.fade-exit-done {
    opacity: 0;
  }
`;
