import { IGetGroupAnalyticsResV2 } from "@dailyhuman/web-core/dist/api/game/res";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { TAnalytics } from "types/analytics";
import { apiRoute, commonHeaders } from "utils/api";

type TAnalyticsGroup = {
  group: {
    id: string;
    name: string;
  };
  analytics: TAnalytics;
  scores?: {
    label: string;
    value: number;
  }[];
};

export default function useAnalyticsGroup(groupId?: string, promptSetId?: string) {
  const { data, isFetching: isAnalyticsGroupLoading } = useQuery({
    queryKey: ["useAnalyticsGroup", groupId, promptSetId],
    queryFn: () =>
      axios.get<IGetGroupAnalyticsResV2>(
        `${apiRoute?.slice(0, -3)}/v2/recognition/analytics/group/${groupId}`,
        {
          ...commonHeaders(),
          params: { promptSetId },
        },
      ),
    enabled: !!groupId, // Ensures the query only runs when groupId is not null or undefined
  });

  if (!data) return { analyticsGroup: undefined, isAnalyticsGroupLoading };

  const { group, analytics, avgScores } = data.data;

  const analyticsGroup: TAnalyticsGroup = {
    group: {
      id: group.id,
      name: group.name,
    },
    analytics: {
      rackStack: group.rackStackValues,
      connectingDots: group.connectingDots,
      words: analytics?.topWordsFreq && {
        count: Object.keys(analytics?.topWordsFreq)
          .map(key => ({
            text: key,
            value: analytics?.topWordsFreq?.[key] || 0,
          }))
          .sort((a, b) => b.value - a.value),
        insight: analytics?.wordBiasInsight,
      },
    },
    scores: avgScores.length
      ? avgScores.map(score => ({
          label: score.label,
          value: score.score || 0,
        }))
      : undefined,
  };

  return { analyticsGroup, isAnalyticsGroupLoading };
}
