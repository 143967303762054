import Quote from "components/base/Quote";
import { ServerMessageWrapper } from "pages/Labs/Labs";
import styled from "styled-components";

interface MessageProps {
  isUserMessage: boolean;
  message: string;
}

export const Message = ({ isUserMessage, message }: MessageProps) => {
  return isUserMessage ? (
    <UserMessageWrapper>{message}</UserMessageWrapper>
  ) : (
    <ServerMessageWrapper>{message}</ServerMessageWrapper>
  );
};

const ServerQuote = styled(Quote)`
  margin: 0 1rem 0 auto;
  max-width: 80%;
  @media (min-width: 768px) {
    max-width: 70%;
  }
`;

const UserMessageWrapper = styled(ServerQuote)`
  padding: 1rem !important;
  > svg {
    display: none;
  }
`;
