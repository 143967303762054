import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { apiRoute, commonHeaders } from "utils/api";

export default function useCoachPromptsets() {
  const { data, isFetching: isPromptsetsLoading } = useQuery({
    queryKey: ["useCoachPromptsets"],
    queryFn: () =>
      axios.get<any>(`${apiRoute}/prompt-set/by-coach`, {
        ...commonHeaders(),
      }),
  });

  const promptsets = data?.data?.data;

  return { promptsets, isPromptsetsLoading };
}
