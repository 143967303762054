import { ComponentProps, useEffect, useRef, useState } from "react";
import styled from "styled-components";

export default function Accordion(props: ComponentProps<"div">) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number | "auto">("auto");

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        // We only have one entry, so we can use entries[0].
        const observedHeight = entries[0].contentRect.height;
        setHeight(observedHeight);
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        // Cleanup the observer when the component is unmounted
        resizeObserver.disconnect();
      };
    }
  }, []);

  return (
    <Container style={{ height }}>
      <div ref={containerRef}>{props.children}</div>
    </Container>
  );
}

const Container = styled.div`
  transition: height 0.4s;
`;
