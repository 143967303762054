import countries from "constants/countries";
import { useFormikContext } from "formik";
import { ComponentProps, useEffect, useState } from "react";
import styled from "styled-components";
import parsePhoneNumber from "utils/parsePhoneNumber";

import Input from "./Input";
import Select from "./Select/Select";

export interface PhoneInputProps<T> extends ComponentProps<typeof TextInput> {
  name?: keyof T;
}

const browserCountry = (navigator as any)?.userLanguage?.split("-")[1] || "us";

export default function PhoneInput<T>({ value, name, ...props }: PhoneInputProps<T>) {
  const { iso2Code, nationalNumber } = parsePhoneNumber(`+${value}`);
  const [code, setCode] = useState(iso2Code || browserCountry);
  const [number, setNumber] = useState(nationalNumber || "");
  const [isFocused, setIsFocused] = useState(false);
  const { setFieldValue, errors, touched, setTouched } = useFormikContext<T>() || {};
  const error = name && touched?.[name] && errors[name];
  const dialCode = countries.find(country => country.iso2 === code)?.dialCode;

  useEffect(() => {
    const value = number.toString().length ? `${dialCode}${number}` : "";
    setFieldValue(name as string, value);
  }, [dialCode, number, name, setFieldValue]);

  const handleBlur = () => {
    setTouched({ ...touched, [name as string]: true });
    setIsFocused(false);
  };

  const handleNumberChange = (e: any) => {
    const value = e.target.value.replace(/\D/g, "");
    setNumber(value);
  };

  return (
    <Container>
      <PhoneCodeInput
        value={iso2Code || code}
        onChange={(e: any) => setCode(e.target.value)}
        error={error?.toString()}
        onBlur={handleBlur}
        focused={isFocused}
      />
      <PhoneNumberInput
        {...props}
        value={number}
        onChange={handleNumberChange}
        onBlur={handleBlur}
        onFocus={() => setIsFocused(true)}
        mask={masks[iso2Code || "default"] || masks.default}
      />
    </Container>
  );
}

export const PhoneCodeInput = (props: ComponentProps<typeof Select>) => {
  return (
    <StyledSelect {...props}>
      {countries.map(({ name, iso2, dialCode }) => {
        return (
          <option key={iso2} id={iso2} value={iso2} label={`${name} +${dialCode}`}>
            {getFlagEmoji(iso2)}{" "}
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 7"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
            >
              <path d="M1 2L6 6L11 2" stroke="black" />
            </svg>
          </option>
        );
      })}
    </StyledSelect>
  );
};

export const PhoneNumberInput = (props: ComponentProps<typeof Input>) => {
  return <TextInput {...props} type="tel" placeholder="Enter Number" />;
};

const StyledSelect = styled(Select)`
  & div:last-child {
    display: none;
  }
`;

const TextInput = styled(Input)`
  left: 3.5rem;
  position: absolute;
  top: 0px;
  width: calc(100% - 3.5rem);
  input {
    background: transparent !important;
    padding-left: 0.5rem;
    &:focus {
      outline-color: transparent;
    }
  }
`;

const Container = styled.div`
  position: relative;
`;

function getFlagEmoji(countryCode: string) {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map(char => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}

const masks: { [key: string]: (string | RegExp)[] } = {
  us: [
    "(",
    /[1-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  br: [
    "(",
    /[1-9]/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  default: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
};
