import { NavLink } from "react-router-dom";

import { ReactComponent } from "./Logo.svg";

export default function Logo() {
  return (
    <NavLink to={"/"}>
      <ReactComponent />
    </NavLink>
  );
}
