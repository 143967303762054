import { QueryClient } from "@tanstack/react-query";
import axios from "axios";

import { BrowserStorageItem, localStorageService } from "./StorageHelper";
import { getAppVersion } from "./version";

export const apiRoute = process.env.REACT_APP_BASE_URL;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export function commonHeaders() {
  return {
    headers: {
      "Content-Type": "application/json",
      "X-ACCESS-TOKEN": `${localStorageService.get(BrowserStorageItem.AUTH_TOKEN)}`,
    },
  };
}

axios.interceptors.request.use(value => {
  value.headers.set("x-client-app", "customer:" + getAppVersion());
  value.headers.set("x-client-tz", Intl.DateTimeFormat().resolvedOptions().timeZone);
  return value;
});

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      // localStorageService.remove(BrowserStorageItem.AUTH_TOKEN);
      // window.location.href = "/";
      return Promise.resolve(error);
    }
    return Promise.reject(error);
  },
);
