import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { apiRoute, commonHeaders } from "utils/api";

export default function useAnalyticsGroupUserLabs(userId?: string) {
  const { data, isFetching: isLabsLoading } = useQuery({
    queryKey: ["useAnalyticsGroupUserLabs", userId],
    queryFn: () =>
      axios.get<any>(`${apiRoute}/game/by-user`, {
        ...commonHeaders(),
        params: { userId },
      }),
    enabled: !!userId, // Ensures the query only runs when userId is not null or undefined
  });

  const labs = data?.data?.data;

  return { labs, isLabsLoading };
}
