import { apiRoute, commonHeaders } from "utils/api";

type useEngagementReportOptions = {
  filter: {
    firstName?: string;
    lastName?: string;
    startDate?: string;
    endDate?: string;
  };
};

function buildQueryParams(params: Record<string, any>) {
  const queryParams: string[] = [];

  function addParam(key: string, value: any) {
    if (value !== undefined && value !== "") {
      queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  }

  // Flatten the object to use dot notation
  Object.entries(params).forEach(([key, value]) => {
    if (typeof value === "object" && value !== null) {
      Object.entries(value).forEach(([subKey, subValue]) =>
        addParam(`${key}[${subKey}]`, subValue),
      );
    } else {
      addParam(key, value);
    }
  });

  return queryParams.join("&");
}

export default function useEngagementReportExport(
  gameid?: string,
  options?: useEngagementReportOptions,
) {
  const params = {
    user: {
      firstName: options?.filter.firstName,
      lastName: options?.filter.lastName,
    },
    labStart: {
      from: options?.filter.startDate,
    },
    labEnd: {
      till: options?.filter.endDate,
    },
  };

  const queryString = buildQueryParams(params);

  const engagementReportExport = () => {
    const headerData = commonHeaders();
    window.location.href = `${apiRoute}/engagement/game/${gameid}/export?x-access-token=${headerData.headers["X-ACCESS-TOKEN"]}&${queryString}`;
  };

  return { engagementReportExport };
}
