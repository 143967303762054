import Fade from "components/animations/Fade";
import { useBackground } from "components/base/layout/Background";
import Heading2 from "components/base/text/Heading2";
import { Footer } from "components/Footer";
import Button, { BorderlessButton } from "components/form/Button";
import { useNavigateWithFade } from "components/PageRouter/PageRouter";
import useGames from "hooks/useGames";
import useGameUnsubscribe from "hooks/useGameUnsubscribe";
import { useParams } from "react-router-dom";
import styled from "styled-components";

export const ProfileGameUnsubscribe = () => {
  useBackground("#f9f7f4");
  const { games } = useGames();
  const { gameid } = useParams<{ gameid: string }>();
  const game = games?.find(game => game.id === gameid);
  const { gameUnsubscribe } = useGameUnsubscribe({ gameid: gameid || "" });
  const navigate = useNavigateWithFade();

  return (
    <PageWrapper delay={300}>
      <Box>
        <Heading2>Confirm cancellation</Heading2>
        <p>If you cancel your subscription, you will be removed from {game?.name}.</p>
        <div>
          <Button onClick={gameUnsubscribe}>Confirm</Button>
          <BorderlessButton onClick={() => navigate("/profile/game/" + gameid)}>
            Not now
          </BorderlessButton>
        </div>
      </Box>
      <Footer />
    </PageWrapper>
  );
};

const PageWrapper = styled(Fade)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  min-height: 100dvh;
`;

const Box = styled.div`
  align-items: center;
  background: #eee7df;
  border-radius: 1rem;
  color: #241f18;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 420px;
  padding: 1rem;
  text-align: center;
`;
