import "./assets/css/index.css";

import { QueryClientProvider } from "@tanstack/react-query";
import Background from "components/base/layout/Background";
import PageRouter from "components/PageRouter";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";
import { queryClient } from "utils/api";

function App() {
  return (
    <HelmetProvider>
      <Background>
        <div className="App">
          <Router>
            <QueryClientProvider client={queryClient}>
              <PageRouter />
            </QueryClientProvider>
          </Router>
        </div>
      </Background>
    </HelmetProvider>
  );
}

export default App;
