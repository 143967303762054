import { IListGameChatHistoryRes } from "@dailyhuman/web-core/dist/api/game/res";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { apiRoute, commonHeaders, queryClient } from "../utils/api";

function useGetGameMessages({
  gameCycleId,
  isEnabled,
}: {
  gameCycleId?: string;
  isEnabled: boolean;
}) {
  const {
    data: messages,
    isFetching: isMessagesLoading,
    error: messagingListError,
    isError: isMessagingListError,
  } = useQuery({
    queryKey: ["getGameMessages"],
    queryFn: async ({ signal }) => {
      const data = await axios.get<IListGameChatHistoryRes>(
        `${apiRoute}/game/cycle/${gameCycleId}/chat-history?waitForProcessing=false`,
        {
          ...commonHeaders(),
          signal,
        },
      );
      if (data?.data.meta?.dataWaitTimeout) {
        queryClient.refetchQueries({ queryKey: ["getGameMessages"] });
      }
      return data;
    },
    enabled: isEnabled && !!gameCycleId,
    refetchInterval: 3000,
  });

  return {
    messages: messages?.data,
    isMessagesLoading,
    messagingListError,
    isMessagingListError,
  };
}

export { useGetGameMessages };
