import { useRef, useState } from "react";
import { apiRoute, commonHeaders } from "utils/api";

/**
 * Custom hook for managing audio recording and transcription.
 */
export default function useTranscribe() {
  const [isRecording, setIsRecording] = useState(false);
  const [transcribeError, setTranscribeError] = useState<string | null>(null);
  const [isFetchingTranscription, setIsFetchingTranscription] = useState(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const isFirefox = navigator.userAgent.toLowerCase().includes("firefox");
  const mimeType = isFirefox ? "video/webm" : "video/mp4";

  const initializeMediaRecorder = (stream: MediaStream) => {
    const mediaRecorder = new MediaRecorder(stream, {
      audioBitsPerSecond: 128000,
      mimeType,
    });

    mediaRecorder.addEventListener("dataavailable", e => {
      console.log("dataavailable");
      return audioChunksRef.current.push(e.data);
    });

    mediaRecorderRef.current = mediaRecorder;
    mediaRecorder.start(1000);

    mediaRecorderRef.current?.addEventListener("stop", async () => {
      console.log("first stop");
    });
  };

  const startRecording = () => {
    setIsRecording(true);

    setTranscribeError(null);
    navigator.mediaDevices
      ?.getUserMedia({ audio: true })
      .then(initializeMediaRecorder)
      .catch(err => {
        console.error(err);
        if (err.name === "NotAllowedError") {
          setTranscribeError("Please allow access to your microphone.");
        }
      });

    return () => mediaRecorderRef.current?.stop();
  };

  const stopRecording = async () => {
    if (mediaRecorderRef.current) {
      const promise = new Promise((resolve, reject) => {
        mediaRecorderRef.current?.addEventListener("stop", async () => {
          setIsRecording(false);
          setIsFetchingTranscription(true);
          const audioBlob = new Blob(audioChunksRef.current, {
            type: mimeType,
          });
          audioChunksRef.current = []; // Clear chunks for the next session
          const result = await fetchTranscriptionData(
            new File([audioBlob], generateFileName("mp4")),
          );

          setIsFetchingTranscription(false);
          resolve(result);
        });
      });
      mediaRecorderRef.current?.stop();

      return promise;
    }
  };

  const cancelRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  return {
    startRecording,
    stopRecording,
    cancelRecording,
    isRecording,
    isFetchingTranscription,
    transcribeError,
  };
}

const generateFileName = (extension: string) =>
  `file_${Date.now()}_${Math.round(Math.random() * 100000)}.${extension}`;

const fetchTranscriptionData = async (file: File) => {
  const token = commonHeaders().headers["X-ACCESS-TOKEN"];
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await fetch(`${apiRoute}/recognition/transcription`, {
      method: "POST",
      headers: { "X-ACCESS-TOKEN": token, Accept: "application/json" },
      body: formData,
    });

    if (!response.ok) throw new Error(`Failed to transcribe: ${response.statusText}`);
    return (await response.json())?.result;
  } catch (err) {
    console.error("Error sending transcription data:", err);
  }
};
