import Accordion from "components/animations/Accordion";
import Switch from "components/animations/Switch";
import Logo from "components/base/Logo";
import Button, { BorderlessButton, BorderlessButtonLink } from "components/form/Button";
import Animation from "components/login/Animation";
import LoginForm from "components/login/LoginForm";
import MagicLinkForm from "components/login/MagicLinkForm";
import { useDeveloperLogin } from "hooks/useDeveloperLogin";
import { useSendMagicLink } from "hooks/useSendMagicLink";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

export const Login = () => {
  const location = useLocation();
  const redirectTo = new URLSearchParams(location.search).get("redirectTo");

  const [step, setStep] = useState<1 | 2 | 3 | 4 | 5>(2);
  const [redirectionLink, setRedirectionLink] = useState("");
  const [email, setEmail] = useState("");
  const { sendMagicLink, isSendingLink } = useSendMagicLink(() => setStep(3));
  const { developerLogin, isDeveloperLoging } = useDeveloperLogin();

  useEffect(() => {
    if (redirectTo) {
      setStep(2);
      setRedirectionLink(redirectTo);
    }
  }, [redirectTo]);

  const checkSteps = useMemo(() => {
    const handleMagicLinkSubmit = (values: any) => {
      setEmail(values.email);
      sendMagicLink({
        email: values.email,
        redirectTo: `/${redirectionLink}` || "/",
      });
    };

    const handleLoginSubmit = (values: any) => developerLogin(values);

    const initialValues = { email: "", password: "" };

    switch (step) {
      case 2:
        return (
          <StepWrapper>
            <MagicLinkForm
              onSubmit={handleMagicLinkSubmit}
              initialValues={initialValues}
              loading={isSendingLink}
            />
            <BorderlessButton onClick={() => setStep(4)}>Need help?</BorderlessButton>
          </StepWrapper>
        );
      case 3:
        return (
          <StepWrapper>
            <h2>Check your email</h2>
            <center>
              <div>
                Your access link was sent to
                <br />
                <span>{email}</span>
              </div>
            </center>
            <StepContent>
              <BorderlessButton onClick={handleMagicLinkSubmit}>
                Resend Code
              </BorderlessButton>
              <BorderlessButton onClick={() => setStep(2)}>Back</BorderlessButton>
            </StepContent>
          </StepWrapper>
        );
      case 4:
        return (
          <StepWrapper>
            <h2>Trouble logging in?</h2>
            <StepContent>
              <Button onClick={() => setStep(5)}>Use your Password</Button>
              <BorderlessButtonLink
                href="https://dailyhuman.com/support"
                target="_blank"
              >
                Contact Us
              </BorderlessButtonLink>
              <BorderlessButton onClick={() => setStep(2)}>Back</BorderlessButton>
            </StepContent>
          </StepWrapper>
        );
      case 5:
        return (
          <StepWrapper>
            <LoginForm
              onSubmit={handleLoginSubmit}
              initialValues={initialValues}
              loading={isDeveloperLoging}
            />
            <BorderlessButton onClick={() => setStep(4)}>Back</BorderlessButton>
          </StepWrapper>
        );
    }
  }, [
    developerLogin,
    email,
    isDeveloperLoging,
    isSendingLink,
    redirectionLink,
    sendMagicLink,
    step,
  ]);

  return (
    <PageWrapper>
      <Content>
        <LeftBlock>
          <Animation />
        </LeftBlock>
        <Accordion>
          <RightBlock>
            <Logo />
            <Switch renderKey={step.toString()} duration={300}>
              {checkSteps}
            </Switch>
          </RightBlock>
        </Accordion>
      </Content>
      <Links>
        <Link href="https://dailyhuman.com/support" target="_blank">
          Contact Us
        </Link>
        <Link href="https://dailyhuman.com/privacy" target="_blank">
          Privacy
        </Link>
        <Link href="https://dailyhuman.com/terms" target="_blank">
          Terms
        </Link>
      </Links>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  align-items: center;
  background: #f9f7f4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100dvh;
  padding: 2rem;
`;

const Content = styled.div`
  align-items: center;
  background: #eee7df;
  border-radius: 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 720px;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  height: 240px;
  width: 240px;
  @media (min-width: 768px) {
    height: 400px;
    width: 400px;
    margin: 2rem 0rem 2rem 2rem;
  }
`;

const RightBlock = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  min-width: 280px;
  padding: 1rem;
  position: relative;
  @media (min-width: 768px) {
    padding: 6rem 2rem;
    min-height: 540px;
  }
`;

const StepWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
  }
`;

const StepContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Links = styled.div`
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
`;

const Link = styled.a`
  color: #363636;
  cursor: pointer;
  font-style: normal;
`;
