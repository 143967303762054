import { IListJourneyV3ResItem } from "@dailyhuman/web-core/dist/api/game/res";
import Accordion from "components/animations/Accordion";
import Switch from "components/animations/Switch";
import Media from "components/base/Media";
import Heading1 from "components/base/text/Heading1";
import Heading3 from "components/base/text/Heading3";
import Small from "components/base/text/Small";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import styled from "styled-components";

import { Section } from "./JourneySection";

export default function JourneyListItem({ cycle }: { cycle: IListJourneyV3ResItem }) {
  return (
    <ItemContainer>
      <ItemCard>
        <ItemCardImage src={cycle.card?.image} alt={cycle.prompt.name} />
        <ItemCardContent>
          <div>
            <Heading3>{cycle.prompt.name}</Heading3>
            <Small>{dayjs(cycle.cycle.startAt).format("MMM DD, YYYY")}</Small>
          </div>
          <div>
            <Heading1>{cycle.card?.name}</Heading1>
            MINDSET CARD
          </div>
        </ItemCardContent>
      </ItemCard>
      <ItemSection>
        {cycle.userData?.selfTalk && (
          <>
            <Expander title="Your Self-Talk" initial={true}>
              <p>
                <HighlightWord match={cycle.card?.name}>
                  {cycle.userData?.selfTalk}
                </HighlightWord>
              </p>
            </Expander>
            <Separator />
          </>
        )}
        {cycle.userData?.reframedStory && (
          <>
            <Expander title={cycle.user.name}>
              <p>
                <HighlightWord match={cycle.card?.name}>
                  {cycle.userData?.reframedStory}
                </HighlightWord>
              </p>
            </Expander>
            <Separator />
          </>
        )}

        {cycle.coachData && (
          <>
            <Expander title={cycle.coachData?.name || "n/a"}>
              <>
                {cycle.coachData?.image && (
                  <>
                    <ItemMedia uri={cycle.coachData?.image} />
                    <br />
                  </>
                )}
                <p>
                  <HighlightWord match={cycle.card?.name}>
                    {cycle.coachData?.reflection}
                  </HighlightWord>
                </p>
              </>
            </Expander>
            <Separator />
          </>
        )}
        {cycle.partnerData && (
          <>
            <Expander title={cycle.partner.name}>
              <p>
                <HighlightWord match={cycle.card?.name}>
                  {cycle.partnerData?.reframedStory || ""}
                </HighlightWord>
              </p>
            </Expander>
          </>
        )}
      </ItemSection>
    </ItemContainer>
  );
}

const HighlightWord = ({ match, children }: { match?: string; children: string }) => (
  <span
    dangerouslySetInnerHTML={{
      __html: children.replace(match || "", `<b style="font-weight: 600">${match}</b>`),
    }}
  />
);

const ItemCard = styled.div`
  margin: 0 auto 1.5rem;
  position: relative;
`;

const ItemCardImage = styled.img`
  border-radius: 1rem;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.2);
  display: block;
  height: 513px;
  margin: 0 auto;
  max-width: 100%;
  object-fit: cover;
  width: 760px;
`;

const ItemCardContent = styled.div`
  bottom: 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  line-height: 1;
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 0;
`;

const ItemMedia = styled(Media)`
  max-width: 560px;
`;

const Separator = styled.div`
  background: #c7bef8;
  height: 1px;
  margin: 1rem 0;
`;

const ItemContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: calc(100% - 1rem);
  width: 760px;
`;

const ItemSection = styled(Section)`
  margin-top: -12rem;
  padding-top: 12rem;
  white-space: pre-line;
`;

/// Expander

type ExpanderProps = {
  title: string;
  children: React.ReactNode;
  initial?: boolean;
  expanded?: boolean;
};

// expander component start
function Expander({ title, children, initial }: ExpanderProps) {
  const [isExpanded, setIsExpanded] = useState(initial);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div ref={ref} className="Expander">
      <ItemTitle
        onClick={() => setIsExpanded(!isExpanded)}
        className={isExpanded ? "active" : ""}
      >
        <svg
          width="20"
          height="18"
          viewBox="-2 0 20 10"
          fill="none"
          style={{
            transform: isExpanded ? "rotate(-180deg)" : "",
            transition: "all 0.6s",
          }}
        >
          <path
            d="M15.778 1.02677L8.38898 8.38898L1 1"
            stroke="#664DF3"
            strokeWidth="2"
          />
        </svg>{" "}
        {title}
      </ItemTitle>
      <Accordion>
        <Switch renderKey={JSON.stringify(isExpanded)}>
          {isExpanded && <ExpanderContent>{children}</ExpanderContent>}
        </Switch>
      </Accordion>
    </div>
  );
}

const ExpanderContent = styled.div`
  padding-top: 0.5rem;
`;

const ItemTitle = styled.h2`
  color: #664df3;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 700;
`;
