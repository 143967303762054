import Button, { BorderlessButton } from "components/form/Button";
import ButtonGroup from "components/form/ButtonGroup";
import EmailInput from "components/form/EmailInput";
import Field from "components/form/Field";
import useUserExists from "hooks/useCheckUserExist";
import useKeyAction from "hooks/utils/useKeyAction";
import styled from "styled-components";

import { useOnboardingContext } from "../components/OnboardingUserFlow";
import { useRegisterFormContext } from "../components/RegisterForm";

export default function EmailStep() {
  const { values, errors, setFieldValue } = useRegisterFormContext();
  const { setMainStep } = useOnboardingContext() || {};
  const { userExistsRefetch, isUserExistsLoading } = useUserExists({
    email: values.email,
  });

  const isDisabled = !values.email || isUserExistsLoading || !!errors.email;

  const handleClick = async (e: KeyboardEvent) => {
    e.preventDefault();
    if (isDisabled) return;
    const { data } = await userExistsRefetch();
    const exists = data?.data.exists;
    setMainStep?.(exists ? "UserExistsStep" : "EmailVerificationStep");
  };

  const handleBackClick = () => {
    setMainStep?.("PhoneStep");
    setFieldValue("email", "");
  };

  useKeyAction({ key: "Enter", action: handleClick });

  return (
    <EmailStepContainer>
      <div>
        <h2>Enter email</h2>
        <Field name="email" as={EmailInput} enterKeyHint="next" />
        <center>
          By entering your email you agree to our{" "}
          <a href="https://www.dailyhuman.com/privacy" target="_blank" rel="noreferrer">
            privacy policy
          </a>
        </center>
      </div>
      <ButtonGroup>
        <Button
          type={"button"}
          disabled={isDisabled}
          loading={isUserExistsLoading}
          onClick={handleClick}
        >
          Continue
        </Button>
        <BorderlessButton onClick={handleBackClick}>Back</BorderlessButton>
      </ButtonGroup>
    </EmailStepContainer>
  );
}

const EmailStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  h2 {
    font-size: 1.375rem;
    font-weight: 500;
    margin-bottom: 2rem;
    text-align: center;
  }
  center {
    font-size: 0.8rem;
    margin: 0.25rem 2rem 1rem;
  }
`;
