import styled from "styled-components";

export interface ButtonGroupProps {
  children: React.ReactNode;
}

export default function ButtonGroup({ children }: ButtonGroupProps) {
  return <StyledButtonGroup>{children}</StyledButtonGroup>;
}

const StyledButtonGroup = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;
