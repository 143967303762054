import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useParams } from "react-router-dom";

import { apiRoute, commonHeaders } from "../utils/api";
import { useGetOnboardingGroupInfo } from "./useGetOnboardingGroupInfo";

type OnboardingSubscription = {
  paddle: {
    transactionId: string;
  };
};

export default function useOnboardingSubscription() {
  const { poolSlug } = useParams<{ poolSlug: string }>();
  const { poolInfo } = useGetOnboardingGroupInfo(poolSlug);
  const poolId = poolInfo?.data.id;

  const { data, isFetching, error } = useQuery({
    queryKey: ["onboardingSubscription"],
    queryFn: () =>
      axios.post<OnboardingSubscription>(
        `${apiRoute}/onboarding/pool/${poolId}/subscription`,
        {},
        commonHeaders(),
      ),
  });

  const onboardingSubscription = data?.data;

  return {
    onboardingSubscription,
    isOnboardingSubscriptionFetching: isFetching,
    onboardingSubscriptionError: error,
  };
}
