import dayjs from "dayjs";

/**
 * Formats a date as a human-readable string, given a date string
 *
 * If the date is today, return "today"
 * If the date is tomorrow, return "tomorrow"
 * If the date is within the next 7 days, return the weekday (e.g. "Monday")
 * If the date is after 7 days from now, return the month and day (e.g. "February 14")
 *
 * Also returns the time in 12-hour format (e.g. "3:45 PM")
 *
 * @param {string} date - The date to format, as a string or date object
 * @returns {string} A human-readable string representing the date and time
 */

export default function Epoch({ date }: { date: string | Date }) {
  const day = dayjs(date).isSame(dayjs(), "day")
    ? "today"
    : dayjs(date).isSame(dayjs().add(1, "day"), "day")
      ? "tomorrow"
      : // if less than 7 days from now, show weekday
        dayjs(date).isBefore(dayjs().add(7, "day"), "day")
        ? dayjs(date).format("dddd")
        : // Show mon day
          dayjs(date).format("MMM D");

  const hour = dayjs(date).format("h:mm A");

  return (
    <>
      {day}, {hour}
    </>
  );
}
