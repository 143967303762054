import styled from "styled-components";

export const Section = styled.div`
  background: #f9f7f4;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  max-width: calc(100% - 2rem);
  padding: 1rem;
  width: 100%;
`;

export const SectionSmall = styled(Section)`
  gap: 1rem;
  margin: 2rem auto;
  margin-bottom: 2rem;
  width: 420px;
`;
