import ErrorMessage from "components/form/ErrorMessage";
import { useConfirmTransport } from "hooks/useConfirmTransport";
import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";

export interface VerificationCodeInputProps {
  length: number;
  onComplete: (code: string) => void;
}

const VerificationCodeInput = ({ length, onComplete }: VerificationCodeInputProps) => {
  const { sendConfirmationRequest, confirmationResponse, reset } =
    useConfirmTransport();

  const [code, setCode] = useState<string[]>(Array(length).fill(""));
  const [errorMessage, setErrorMessage] = useState("");

  const focusToNextInput = (target: HTMLElement) => {
    const nextElementSibling = target.nextElementSibling as HTMLInputElement | null;
    nextElementSibling?.focus();
  };

  const focusToPrevInput = (target: HTMLElement) => {
    const prevElementSibling = target.previousElementSibling as HTMLInputElement | null;
    prevElementSibling?.focus();
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
    value: string,
  ) => {
    if (value.length === 1 || value.length === 0) {
      const updatedCode = [...code];
      updatedCode[index] = value.toUpperCase();
      setCode(updatedCode);

      if (value.length === 1) {
        focusToNextInput(event.target);
      }
    } else if (value.length === length) {
      setCode(value.split(""));
      event.target.blur();
    }
  };

  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const target = e.target as HTMLInputElement;
    const targetValue = target.value;

    if (key === "ArrowRight" || key === "ArrowDown") {
      e.preventDefault();
      return focusToNextInput(target);
    }

    if (key === "ArrowLeft" || key === "ArrowUp") {
      e.preventDefault();
      return focusToPrevInput(target);
    }

    if (key !== "Backspace" || target.value !== "") {
      return;
    }

    target.setSelectionRange(0, targetValue.length);
    focusToPrevInput(target);
  };

  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e;
    target.setSelectionRange(0, target.value.length);
  };

  useEffect(() => {
    if (code.join("").length !== length) return;
    sendConfirmationRequest({
      code: code.join(""),
    });
  }, [code, length, sendConfirmationRequest]);

  useEffect(() => {
    if (!confirmationResponse?.data) return;
    if (confirmationResponse.data.confirmed) {
      onComplete(code.join(""));
      reset();
      setCode(Array(length).fill(""));
    } else {
      setErrorMessage("Incorrect validation code");
    }
  }, [code, confirmationResponse, length, onComplete, reset]);

  return (
    <VerificationContainer>
      <CodeInputContainer>
        {code.map((char, index) => (
          <CodeInput
            autoFocus={index === 0}
            key={index}
            type="text"
            maxLength={length}
            value={char}
            onKeyDown={inputOnKeyDown}
            onFocus={inputOnFocus}
            onChange={event => handleChange(event, index, event.target.value)}
            autoComplete={index === 0 ? "one-time-code" : "off"}
          />
        ))}
      </CodeInputContainer>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </VerificationContainer>
  );
};

export default VerificationCodeInput;

const VerificationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CodeInputContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  margin: 20px 0;
`;

const CodeInput = styled.input`
  border: 2px transparent;
  border-radius: 99px;
  font-size: 1.375rem;
  height: 64px;
  padding: 0;
  text-align: center;
  width: 32px;
`;
