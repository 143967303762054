import { useRive } from "@rive-app/react-canvas";
import { ComponentProps } from "react";

import AnimationFile from "./Loading.riv";

const stateMachine = "State Machine 1";

export default function Loading({
  style,
  ...props
}: ComponentProps<typeof RiveComponent>) {
  const { RiveComponent } = useRive({
    src: AnimationFile,
    stateMachines: stateMachine,
    autoplay: true,
    shouldDisableRiveListeners: true,
  });

  return (
    <RiveComponent
      style={{ width: "300px", maxWidth: "100%", aspectRatio: 1, ...style }}
      {...props}
    />
  );
}
