import { Field as FormikField } from "formik";
import { ComponentProps } from "react";
import styled from "styled-components";

import ErrorMessage from "./ErrorMessage";
import Label from "./Label";
import Toggle from "./Toggle";

export interface FieldProps extends ComponentProps<typeof FormikField> {
  label?: string;
}

const inlineFields = [Toggle];

export default function Field({ label, ...props }: FieldProps) {
  const isInline = inlineFields.includes(props.as);

  return (
    <div>
      <FieldWrapper isInline={isInline}>
        {label && <Label htmlFor={props.name}>{label}</Label>}
        <FormikField {...props} />
      </FieldWrapper>
      <ErrorMessage name={props.name} />
    </div>
  );
}

const FieldWrapper = styled.div<{ isInline: boolean }>`
  ${({ isInline }) =>
    isInline &&
    `display: flex;
    align-items: center;
    & > *:first-child {
      flex: 1;
      font-weight: 500;
    }
    & ~ * {
      position: relative;
      top: -0.5rem;
    }
  `}
`;
