import Card from "components/base/Box";
import Quote from "components/base/Quote";
import Heading2 from "components/base/text/Heading2";
import LinearChart from "components/chart/LinearChart";
import { useState } from "react";
import styled from "styled-components";
import { TCapacityHistoryItem } from "types/analytics";

export default function CapacitySwiperItem({
  data,
  label,
  chartEnabled = false,
  initialSelected,
}: {
  data: TCapacityHistoryItem[];
  label: string;
  chartEnabled?: boolean;
  initialSelected?: number;
}) {
  const [selected, setSelected] = useState<number>(
    initialSelected !== undefined ? initialSelected : data.length - 1,
  );
  const insight = data?.[selected]?.insight;
  const hasEnoughData = data.length > 2;

  return (
    <Card style={{ height: "100%", width: "720px", maxWidth: "100%" }}>
      <ChartHeading>{label}</ChartHeading>
      {chartEnabled && hasEnoughData && (
        <LinearChart data={data} onSelected={setSelected} selected={selected} />
      )}
      {insight && <Quote style={{ marginTop: "1rem" }}>{insight}</Quote>}
    </Card>
  );
}

const ChartHeading = styled(Heading2)`
  margin-bottom: 1rem;
  text-align: center;
`;
