import { ComponentProps } from "react";
import styled from "styled-components";

import { ReactComponent as QuoteIcon } from "./Quote.svg";

export default function Quote({
  children,
  ...props
}: ComponentProps<typeof Container>) {
  return (
    <Container {...props}>
      <QuoteIconStyled />
      {children}
    </Container>
  );
}

const Container = styled.div`
  background: #eee7df;
  border-radius: 1rem;
  padding: 1rem 1.5rem;
  position: relative;
  width: fit-content;
  word-break: break-word;
`;

const QuoteIconStyled = styled(QuoteIcon)`
  fill: #00d3e6;
  left: -10px;
  position: absolute;
  top: -10px;
`;
