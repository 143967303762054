import Small from "components/base/text/Small";
import styled from "styled-components";
import { TCapacityHistoryItem } from "types/analytics";

export default function Header({
  selectedData,
}: {
  selectedData: TCapacityHistoryItem;
}) {
  const { value, avg, min, max, insight } = selectedData;

  return (
    <HeaderContainer>
      <div>
        <div>
          <Small>Average</Small>
          <div>
            <AvgIcon />
            <span>{typeof avg !== "number" ? "N/A" : Math.floor(avg)}</span>
          </div>
        </div>
        <div>
          <Small>Max</Small>
          <div>
            <MaxIcon />
            <span>{typeof max !== "number" ? "N/A" : Math.floor(max)}</span>
          </div>
        </div>
        <div>
          <Small>Min</Small>
          <div>
            <MinIcon />
            <span>{typeof min !== "number" ? "N/A" : Math.floor(min)}</span>
          </div>
        </div>
      </div>
      <div>
        <div>
          {insight ? (
            <>
              <svg width={64} height={18} viewBox="0 0 64 12">
                <circle cx={32} cy={6} r={5} fill="#664DF3" />
              </svg>
              <Prompt>{typeof value !== "number" ? "N/A" : Math.floor(value)}</Prompt>
            </>
          ) : (
            <SkippedIcon />
          )}
        </div>
        <div>
          <Small>{!!insight || "Skipped"} Prompt</Small>
        </div>
      </div>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  & > div {
    background: #eee7df;
    border-radius: 0.5rem;
    padding: 0.5rem;
    width: 50%;
  }
  & > div:first-child {
    & > div {
      display: flex;
      justify-content: space-between;
      div span {
        color: #664df3;
        font-weight: 600;
        margin-left: 0.25rem;
      }
    }
  }
  & > div:last-child {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1;
    text-align: center;
  }
`;

const Prompt = styled.span`
  color: #664df3;
  display: block;
  font-size: 2rem;
  font-weight: bold;
`;

const AvgIcon = () => (
  <svg width={16} height={12} viewBox="0 0 16 2">
    <line
      x1="0"
      y1="1"
      x2="16"
      y2="1"
      stroke="#664DF3"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="3 4"
    />
  </svg>
);

const MaxIcon = () => (
  <svg width={15} height={12} viewBox="0 0 12 13">
    <path
      d="M1 2.05274L11 2.05274"
      stroke="#664DF3"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M6 11.9668L6 5.42134"
      stroke="#664DF3"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M3 8.05273L5.99994 5.05556L8.99989 8.05551"
      stroke="#664DF3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const MinIcon = () => (
  <svg width={15} height={12} viewBox="0 0 12 13">
    <path
      d="M1 10.9668L11 10.9668"
      stroke="#664DF3"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M6 1.05274L6 7.59819"
      stroke="#664DF3"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M3 4.9668L5.99994 7.96402L8.99989 4.96407"
      stroke="#664DF3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SkippedIcon = () => (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none">
    <circle
      cx="14"
      cy="14"
      r="14"
      transform="matrix(1 0 0 -1 0.916016 28.9648)"
      fill="#E45D57"
    />
    <path
      d="M7.91602 21.9648L21.916 7.96484M21.916 21.9648L7.91602 7.96484"
      stroke="#F9F7F4"
      strokeWidth="3.5"
      strokeLinecap="round"
    />
  </svg>
);
