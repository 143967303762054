import { SubmitButton } from "components/form/Button";
import ButtonGroup from "components/form/ButtonGroup";
import EmailInput from "components/form/EmailInput";
import Field from "components/form/Field";
import Input from "components/form/Input";
import { Form, Formik } from "formik";
import { ComponentProps } from "react";
import styled from "styled-components";
import { emailValidation } from "utils/validation";
import * as yup from "yup";

const validationSchema = yup.object({
  email: emailValidation,
  password: yup.string().required(),
});

export interface LoginFormProps extends ComponentProps<typeof Formik> {
  loading?: boolean;
}

export default function LoginForm({ loading, ...props }: LoginFormProps) {
  return (
    <Formik {...props} validationSchema={validationSchema}>
      <Form>
        <FieldContainer>
          <Field as={EmailInput} name="email" autoComplete="username" />
        </FieldContainer>
        <FieldContainer>
          <Field
            as={Input}
            name="password"
            type="password"
            placeholder="Enter Password"
            autoComeplete="current-password"
          />
        </FieldContainer>
        <ButtonGroup>
          <SubmitButton loading={loading}>Login</SubmitButton>
        </ButtonGroup>
      </Form>
    </Formik>
  );
}

const FieldContainer = styled.div`
  margin-bottom: 0.5rem;
`;
