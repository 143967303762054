import { useRive, useStateMachineInput } from "@rive-app/react-canvas";

import LoadingRiv from "./Loading.riv";

const stateMachine = "State Machine 1";

type LoadingProps = {
  loop?: boolean;
};

export default function Loading({ loop }: LoadingProps) {
  const { rive, RiveComponent } = useRive({
    src: LoadingRiv,
    stateMachines: stateMachine,
    autoplay: true,
    shouldDisableRiveListeners: true,
  });

  const loopInput = useStateMachineInput(rive, stateMachine, "Loop");

  if (loopInput) {
    loopInput.value = !!loop;
  }

  return <RiveComponent />;
}
