// a hooks that fires an function when the user scrolls after 80% of the document

import { useEffect, useState } from "react";

type useScrollActionProps = {
  action: () => void;
};

export default function useScrollAction({ action }: useScrollActionProps) {
  const [shouldTrigger, setShouldTrigger] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollPercent = (scrollTop / (scrollHeight - clientHeight)) * 100;

      if (scrollPercent >= 90 && shouldTrigger) {
        setShouldTrigger(false);
        action();
        setTimeout(() => setShouldTrigger(true), 3000);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [action, shouldTrigger]);
}
