import { ComponentProps, createContext, useContext, useEffect, useState } from "react";
import styled from "styled-components";

import AnimatedBackground from "./AnimatedBackground";

type BackgroundContext = {
  setColor: React.Dispatch<React.SetStateAction<string>>;
  setAnimated: React.Dispatch<React.SetStateAction<boolean>>;
};

const backgroundContext = createContext<BackgroundContext>({
  setColor: () => {},
  setAnimated: () => {},
});

export const useBackground = (color: string, animated: boolean = false) => {
  const { setColor, setAnimated } = useContext(backgroundContext);
  useEffect(() => {
    setColor(color);
    setAnimated(animated);
  }, [animated, color, setAnimated, setColor]);
  return color;
};

export default function Background({
  children,
  ...props
}: ComponentProps<typeof Container>) {
  const [color, setColor] = useState("#eee7df");
  const [animated, setAnimated] = useState(false);

  return (
    <backgroundContext.Provider value={{ setColor, setAnimated }}>
      <Container color={animated ? "transparent" : color} {...props}>
        <AnimatedBackground color={color} speed={0.3} />
        {children}
      </Container>
    </backgroundContext.Provider>
  );
}

const Container = styled.div<{ color?: string }>`
  background: ${({ color }) => color};
  min-height: 100dvh;
  transition: background 0.5s ease-in 0.1s;
`;
